import axios from 'axios';

const getOrdersApi = async (token , lang) => {
    try {
        const res = await axios.get('/getOrders' , {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            Authorization : token,
            lang: lang
        }
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default getOrdersApi;