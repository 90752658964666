import React, { useEffect, useState } from 'react';
import './AllCategories.css';
import { Grid } from '@mui/material';
import CardForImg from '../../Images/Box.png'
import CardForImg1 from '../../Images/Box1.png'
import CardForImg2 from '../../Images/Camel.png'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getCategoriesApi from '../../apis/categories/getCategoriesApi';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
const AllCategories = () => {
    const {t} = useTranslation()
    const customApiRequest = useCustomApiRequest();
    const [categories , setCategories] = useState();
    const lang = localStorage.getItem("lang");
    useEffect(() => {
        customApiRequest(
            getCategoriesApi(lang),
            (res) => {
                if (res?.status && res?.data?.data?.categories) {
                    setCategories(res?.data?.data?.categories)
                }
            }
        )
    } , [lang])

    return (
        <div className='categories_section'>
            <div className="categories_section-content">
                <div className="categories_section-content-text">
                    <h6>{t('categ-text')}</h6>
                    <svg width="22" height="40" viewBox="0 0 26 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8613 0.268555L-0.000976562 24.7927L12.8613 49.3168L25.7341 24.7927L12.8613 0.268555Z" fill="#FF9C54"/>
                    </svg>
                    <span>“Jacques Cousteau”</span>
                </div>
            </div>
            <div className="categories_section-cards">
                <div>
                    <Grid container spacing={1}>
                        {
                            categories?.length ? categories.map((category) => (
                                <Grid item lg={2} md={3} xs={6}>
                                    <div className="categories_section-card">
                                        <img src={category?.image} alt="" />
                                        <div className="categories_section-card-details">
                                            <h3>{category.title}</h3>
                                            <div className='adventures-data'>
                                                <div>
                                                    {/* {category?.count > 0 && <span>{t('tours-available')}</span>} */}
                                                    <span>{t('tours-available')}</span>
                                                    {category?.count > 0 ? <b>({category?.count})</b> : <b>{t('no tours')}</b>}
                                                </div>
                                                <Link to={`/tours?category_id=${category.id}`} className={document.documentElement.dir === 'rtl' ? 'icon-rtl' : 'icon-ltr'}>
                                                    <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.5625 31.1875C1.94727 31.1875 1.40039 30.9824 0.990234 30.5723C0.101562 29.752 0.101562 28.3164 0.990234 27.4961L12.543 15.875L0.990234 4.32227C0.101562 3.50195 0.101562 2.06641 0.990234 1.24609C1.81055 0.357422 3.24609 0.357422 4.06641 1.24609L17.1914 14.3711C18.0801 15.1914 18.0801 16.627 17.1914 17.4473L4.06641 30.5723C3.65625 30.9824 3.10938 31.1875 2.5625 31.1875Z" fill="#FFC300"/>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            ))
                            : 
                            categories?.length > 0 &&
                            <div className='loading-data'>
                                <Spin />
                            </div>
                        }
                        {/* <Grid item md={2} xs={6}>
                            <div className="categories_section-card">
                                <img src={CardForImg} alt="" />
                                <div className="categories_section-card-details">
                                    <h3>in air adventures</h3>
                                    <div className='adventures-data'>
                                        <div>
                                            <b>350 </b>
                                            <span>Tour Package</span>
                                        </div>
                                        <Link to='/tours'>
                                            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5625 31.1875C1.94727 31.1875 1.40039 30.9824 0.990234 30.5723C0.101562 29.752 0.101562 28.3164 0.990234 27.4961L12.543 15.875L0.990234 4.32227C0.101562 3.50195 0.101562 2.06641 0.990234 1.24609C1.81055 0.357422 3.24609 0.357422 4.06641 1.24609L17.1914 14.3711C18.0801 15.1914 18.0801 16.627 17.1914 17.4473L4.06641 30.5723C3.65625 30.9824 3.10938 31.1875 2.5625 31.1875Z" fill="#FFC300"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <div className="categories_section-card">
                                <img src={CardForImg1} alt="" />
                                <div className="categories_section-card-details">
                                    <h3>in air adventures</h3>
                                    <div className='adventures-data'>
                                        <div>
                                            <b>350 </b>
                                            <span>Tour Package</span>
                                        </div>
                                        <Link to='/tours'>
                                            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5625 31.1875C1.94727 31.1875 1.40039 30.9824 0.990234 30.5723C0.101562 29.752 0.101562 28.3164 0.990234 27.4961L12.543 15.875L0.990234 4.32227C0.101562 3.50195 0.101562 2.06641 0.990234 1.24609C1.81055 0.357422 3.24609 0.357422 4.06641 1.24609L17.1914 14.3711C18.0801 15.1914 18.0801 16.627 17.1914 17.4473L4.06641 30.5723C3.65625 30.9824 3.10938 31.1875 2.5625 31.1875Z" fill="#FFC300"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <div className="categories_section-card">
                                <img src={CardForImg2} alt="" />
                                <div className="categories_section-card-details">
                                    <h3>in air adventures</h3>
                                    <div className='adventures-data'>
                                        <div>
                                            <b>350 </b>
                                            <span>Tour Package</span>
                                        </div>
                                        <Link to='/tours'>
                                            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5625 31.1875C1.94727 31.1875 1.40039 30.9824 0.990234 30.5723C0.101562 29.752 0.101562 28.3164 0.990234 27.4961L12.543 15.875L0.990234 4.32227C0.101562 3.50195 0.101562 2.06641 0.990234 1.24609C1.81055 0.357422 3.24609 0.357422 4.06641 1.24609L17.1914 14.3711C18.0801 15.1914 18.0801 16.627 17.1914 17.4473L4.06641 30.5723C3.65625 30.9824 3.10938 31.1875 2.5625 31.1875Z" fill="#FFC300"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <div className="categories_section-card">
                                <img src={CardForImg} alt="" />
                                <div className="categories_section-card-details">
                                    <h3>in air adventures</h3>
                                    <div className='adventures-data'>
                                        <div>
                                            <b>350 </b>
                                            <span>Tour Package</span>
                                        </div>
                                        <Link to='/tours'>
                                            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5625 31.1875C1.94727 31.1875 1.40039 30.9824 0.990234 30.5723C0.101562 29.752 0.101562 28.3164 0.990234 27.4961L12.543 15.875L0.990234 4.32227C0.101562 3.50195 0.101562 2.06641 0.990234 1.24609C1.81055 0.357422 3.24609 0.357422 4.06641 1.24609L17.1914 14.3711C18.0801 15.1914 18.0801 16.627 17.1914 17.4473L4.06641 30.5723C3.65625 30.9824 3.10938 31.1875 2.5625 31.1875Z" fill="#FFC300"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <div className="categories_section-card">
                                <img src={CardForImg1} alt="" />
                                <div className="categories_section-card-details">
                                    <h3>in air adventures</h3>
                                    <div className='adventures-data'>
                                        <div>
                                            <b>350 </b>
                                            <span>Tour Package</span>
                                        </div>
                                        <Link to='/tours'>
                                            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5625 31.1875C1.94727 31.1875 1.40039 30.9824 0.990234 30.5723C0.101562 29.752 0.101562 28.3164 0.990234 27.4961L12.543 15.875L0.990234 4.32227C0.101562 3.50195 0.101562 2.06641 0.990234 1.24609C1.81055 0.357422 3.24609 0.357422 4.06641 1.24609L17.1914 14.3711C18.0801 15.1914 18.0801 16.627 17.1914 17.4473L4.06641 30.5723C3.65625 30.9824 3.10938 31.1875 2.5625 31.1875Z" fill="#FFC300"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <div className="categories_section-card">
                                <img src={CardForImg2} alt="" />
                                <div className="categories_section-card-details">
                                    <h3>in air adventures</h3>
                                    <div className='adventures-data'>
                                        <div>
                                            <b>350 </b>
                                            <span>Tour Package</span>
                                        </div>
                                        <Link to='/tours'>
                                            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.5625 31.1875C1.94727 31.1875 1.40039 30.9824 0.990234 30.5723C0.101562 29.752 0.101562 28.3164 0.990234 27.4961L12.543 15.875L0.990234 4.32227C0.101562 3.50195 0.101562 2.06641 0.990234 1.24609C1.81055 0.357422 3.24609 0.357422 4.06641 1.24609L17.1914 14.3711C18.0801 15.1914 18.0801 16.627 17.1914 17.4473L4.06641 30.5723C3.65625 30.9824 3.10938 31.1875 2.5625 31.1875Z" fill="#FFC300"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Grid> */}
                    </Grid>
                </div>
            </div>

            <div className='categories_sun'>
                <svg width="178" height="99" viewBox="0 0 178 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M88.7887 54.8322C115.719 55.0904 137.756 31.4196 138.01 1.97279L114.437 1.7468C114.306 16.9627 102.929 29.1872 89.011 29.0537C75.1071 28.9205 63.9287 16.4797 64.0599 1.26384L40.4873 1.03785C40.2333 30.4847 61.8585 54.574 88.7887 54.8322Z" fill="#FEAE17"/>
                    <path d="M89.0166 29.049C102.92 29.1823 114.312 16.9579 114.443 1.74202L89.2821 1.5008L64.0794 1.25919C63.9342 16.4749 75.0987 28.9155 89.0166 29.049Z" fill="#004F6C"/>
                    <path d="M177.166 2.31771L154.791 2.1032C154.752 6.59949 154.283 10.9632 153.521 15.2241L177.166 2.31771Z" fill="#FF9C54"/>
                    <path d="M150.59 16.8482C151.593 12.0884 152.165 7.16732 152.209 2.08411L146.397 2.0284C146.341 8.51091 145.381 14.7563 143.658 20.623L150.59 16.8482Z" fill="#004F6C"/>
                    <path d="M102.163 68.8759L105.752 61.3765C100.359 63.1512 94.625 64.095 88.7043 64.0383C82.7698 63.9814 77.0666 62.9422 71.7051 61.0502L75.1647 68.6171C79.516 69.7005 84.0106 70.3428 88.6496 70.3873C93.2885 70.4317 97.8073 69.8757 102.163 68.8759Z" fill="#004F6C"/>
                    <path d="M100.638 72.0687C96.7295 72.8308 92.7266 73.2493 88.6162 73.2099C84.5059 73.1705 80.511 72.661 76.6164 71.8384L88.4052 97.6806L100.638 72.0687Z" fill="#FF9C54"/>
                    <path d="M141.919 39.0491L139.606 30.974C134.117 42.1726 125.608 51.3147 115.26 57.1124L122.594 59.7956C130.332 54.4869 136.928 47.3968 141.919 39.0491Z" fill="#004F6C"/>
                    <path d="M125.768 60.9505L150.828 70.1004L142.927 42.5391C138.281 49.7622 132.458 56.003 125.768 60.9505Z" fill="#FF9C54"/>
                    <path d="M38.3836 30.0234L35.9315 38.0528C40.7775 46.5091 47.2508 53.7103 54.8957 59.1665L62.2751 56.6243C52.0292 50.6149 43.6788 41.3254 38.3836 30.0234Z" fill="#004F6C"/>
                    <path d="M34.8596 41.5196L26.4854 68.9248L51.6983 60.257C45.0951 55.1963 39.381 48.8448 34.8596 41.5196Z" fill="#FF9C54"/>
                    <path d="M23.7014 0.883636L1.3408 0.669264L24.7584 14.0269C24.0567 9.76615 23.6626 5.37992 23.7014 0.883636Z" fill="#FF9C54"/>
                    <path d="M34.5171 19.6224C32.8954 13.7236 32.0434 7.46083 32.0993 0.97832L26.2876 0.922605C26.2438 5.99153 26.7447 10.9372 27.6513 15.7153L34.5171 19.6224Z" fill="#004F6C"/>
                </svg>
            </div>
            <div className="categories_zigzag">
                <svg width="50%" height="37" viewBox="0 0 767 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M742.53 36.981L718.551 15.342L694.572 36.981L670.802 15.5358L647.033 36.981L623.033 15.342L599.054 36.981L594.911 33.2345L594.89 33.2561L575.054 15.3635L555.218 33.2561L555.177 33.2345L551.054 36.981L527.055 15.342L503.076 36.981L479.139 15.385L455.202 36.981L431.223 15.342L407.244 36.981L383.265 15.342L359.286 36.981L335.307 15.342L311.307 36.981L287.538 15.5358L263.768 36.981L239.789 15.342L215.81 36.981L211.667 33.2345L211.646 33.2561L191.81 15.3635L171.953 33.2561L171.933 33.2345L167.79 36.981L143.811 15.342L119.832 36.981L95.8947 15.385L71.9577 36.981L47.9787 15.342L23.9789 36.981L0 15.342V0.485352L23.9789 22.1244L47.9787 0.485352L71.9158 22.0813L95.8529 0.485352L95.8947 0.528423L95.9365 0.485352L119.874 22.0813L143.811 0.485352L167.79 22.1244L191.768 0.485352L191.81 0.506888L191.831 0.485352L215.81 22.1244L239.789 0.485352L263.559 21.9306L287.328 0.485352L287.538 0.679142L287.768 0.485352L311.537 21.9306L335.307 0.485352L359.286 22.1244L383.244 0.485352L383.265 0.506888V0.485352L407.244 22.1244L431.223 0.485352L455.16 22.0813L479.097 0.485352L479.139 0.528423L479.181 0.485352L503.118 22.0813L527.055 0.485352L551.054 22.1244L575.033 0.485352L575.054 0.506888L575.075 0.485352L599.054 22.1244L623.033 0.485352L646.803 21.9306L670.572 0.485352L670.802 0.679142L671.012 0.485352L694.781 21.9306L718.551 0.485352L742.53 22.1244L766.509 0.485352V15.342L742.53 36.981Z" fill="#C1FDFF"/>
                </svg>
            </div>
            <div className="categories_tree">
                <svg width="77" height="124" viewBox="0 0 87 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M43.3336 143.651L86.6881 64.3945H0L43.3336 143.651Z" fill="#FFC800"/>
                    <path d="M43.3336 113.853L86.6881 34.5957H0L43.3336 113.853Z" fill="#007699"/>
                    <path d="M43.3336 79.6168L86.6881 0.381348H0L43.3336 79.6168Z" fill="#004F6C"/>
                </svg>
                <svg width="135" height="250" viewBox="0 0 135 491" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M75.7345 91.1675H59.623V490.629H75.7345V91.1675Z" fill="#0087A7"/>
                    <path d="M67.682 177.466L0.892578 77.5607L7.16978 73.4482L67.682 163.966L128.173 73.4482L134.471 77.5607L67.682 177.466Z" fill="#0087A7"/>
                    <path d="M67.682 104.472L0.892578 4.54505L7.16978 0.454102L67.682 90.9719L128.173 0.454102L134.471 4.54505L67.682 104.472Z" fill="#0087A7"/>
                </svg>
            </div>
        </div>
    )
}

export default AllCategories