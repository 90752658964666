import React from 'react'
import './Notifications.css'
import { useEffect } from 'react';
import getNotificationsApi from '../../apis/notifications/getNotificationsApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Cookies from 'js-cookie';
import deleteNotificationApi from '../../apis/notifications/deleteNotificationApi';
const Notifications = () => {
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const {t} = useTranslation()
    const customApiRequest = useCustomApiRequest();
    const lang = localStorage.getItem("lang");
    const [notifications , getNotifications] = useState()
    useEffect(() => {
        customApiRequest(
            getNotificationsApi(user?.token , lang),
            (res) => {
                if (res?.status && res?.data?.data) {
                    getNotifications(res?.data?.data)
                }
            }
        )
    } , [])

    const deleteNotification = (id) => {
        customApiRequest(
            deleteNotificationApi(user?.token , {notofication_id : id}),
            (res) => {
                if (res?.status && res?.data?.data) {
                    getNotifications(res?.data?.data)
                }
            }
        )
    }
    return (
        <div className='Notifications'>
            <div className="Notifications-content">
                <h2>{t('Notification')}</h2>
                <div className='Notifications-content-status'>
                    <div className="Notifications-title"><h3>{t('Today')}</h3></div>
                    {
                        notifications?.today?.length ? notifications?.today?.map((notify) => (
                            <div className="Notifications-content-notify" key={notify?.id}>
                                <span>
                                    <img src={notify?.image} alt="notify" />
                                </span>
                                <div className='Notifications-content-info'>
                                    <h4>{notify?.subject}</h4>
                                    <p>{notify?.message}</p>
                                </div>
                                <span onClick={() => deleteNotification(notify?.id)}>
                                    <svg width='20px' height='30px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f70202"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14 11V17" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M4 7H20" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                </span>
                            </div>
                        ))
                        :
                        <div className="Notifications-content-notify">{t('no-notification')}</div>
                    }
                    {/* <div className="Notifications-content-notify">
                        <span>
                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.5268 20.2146C30.5268 18.0915 32.2499 16.3685 34.373 16.3685V14.83C34.373 8.67616 32.8345 7.1377 26.6807 7.1377H11.2961C5.14222 7.1377 3.60376 8.67616 3.60376 14.83V15.5992C5.72684 15.5992 7.44991 17.3223 7.44991 19.4454C7.44991 21.5685 5.72684 23.2915 3.60376 23.2915V24.0608C3.60376 30.2146 5.14222 31.7531 11.2961 31.7531H26.6807C32.8345 31.7531 34.373 30.2146 34.373 24.0608C32.2499 24.0608 30.5268 22.3377 30.5268 20.2146Z" stroke="#048EAB" strokeWidth="2.3195" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.9114 7.1377L15.9114 31.7531" stroke="#048EAB" strokeWidth="2.3195" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="7.73 7.73"/>
                            </svg>
                        </span>
                        <div className='Notifications-content-info'>
                            <h4>Your ticket is ready</h4>
                            <p>Your ticket is ready, check...</p>
                        </div>
                    </div>
                    <div className="Notifications-content-notify">
                        <span>
                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.66369 22.9776L4.32525 20.6391C3.3714 19.6853 3.3714 18.116 4.32525 17.1622L6.66369 14.8237C7.06369 14.4237 7.38677 13.639 7.38677 13.0852V9.77747C7.38677 8.42363 8.49447 7.31597 9.84832 7.31597H13.156C13.7098 7.31597 14.4945 6.99294 14.8945 6.59294L17.2329 4.25445C18.1868 3.3006 19.756 3.3006 20.7099 4.25445L23.0483 6.59294C23.4483 6.99294 24.2329 7.31597 24.7868 7.31597H28.0945C29.4483 7.31597 30.556 8.42363 30.556 9.77747V13.0852C30.556 13.639 30.8791 14.4237 31.2791 14.8237L33.6176 17.1622C34.5714 18.116 34.5714 19.6853 33.6176 20.6391L31.2791 22.9776C30.8791 23.3776 30.556 24.1622 30.556 24.7161V28.0236C30.556 29.3774 29.4483 30.4853 28.0945 30.4853H24.7868C24.2329 30.4853 23.4483 30.8083 23.0483 31.2083L20.7099 33.5468C19.756 34.5007 18.1868 34.5007 17.2329 33.5468L14.8945 31.2083C14.4945 30.8083 13.7098 30.4853 13.156 30.4853H9.84832C8.49447 30.4853 7.38677 29.3774 7.38677 28.0236V24.7161C7.38677 24.1468 7.06369 23.3622 6.66369 22.9776Z" stroke="#048EAB" strokeWidth="2.3195" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.373 23.5003L23.6038 14.2695" stroke="#048EAB" strokeWidth="2.3195" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22.8262 22.7311H22.84" stroke="#048EAB" strokeWidth="3.09267" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.1338 15.0388H15.1476" stroke="#048EAB" strokeWidth="3.09267" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <div className='Notifications-content-info'>
                            <h4>Your YASS Pass is Reedy</h4>
                            <p>Get summer special promo...</p>
                        </div>
                    </div> */}
                </div>
                <div className='Notifications-content-status'>
                    <div className="Notifications-title"><h3>{t('Yesterday')}</h3></div>
                    {
                        notifications?.last?.length ? notifications?.last?.map((notify) => (
                            <div className="Notifications-content-notify" key={notify?.id}>
                                <div>
                                    <span>
                                        <img src={notify?.image} alt="notify" />
                                    </span>
                                    <div className='Notifications-content-info'>
                                        <h4>{notify?.subject}</h4>
                                        <p>{notify?.message}</p>
                                    </div>
                                </div>
                                <span onClick={() => deleteNotification(notify?.id)}>
                                    <svg width='20px' height='30px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f70202"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M14 11V17" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M4 7H20" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#ed0c0c" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                </span>
                            </div>
                        ))
                        :
                        <div className="Notifications-content-notify">{t('no-notification')}</div>
                    }
                </div>
                {/* <div className='Notifications-content-status'>
                    <div className="Notifications-title"><h3>25/4/2023</h3></div>
                    <div className="Notifications-content-notify">
                        <span>
                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.0334 34.3686C16.1501 34.3686 12.2668 33.7519 8.58344 32.5186C7.18344 32.0352 6.11677 31.0519 5.65011 29.7686C5.16677 28.4852 5.33344 27.0686 6.10011 25.8019L8.01677 22.6186C8.41677 21.9519 8.78344 20.6186 8.78344 19.8352V15.0186C8.78344 8.81855 13.8334 3.76855 20.0334 3.76855C26.2334 3.76855 31.2834 8.81855 31.2834 15.0186V19.8352C31.2834 20.6019 31.6501 21.9519 32.0501 22.6352L33.9501 25.8019C34.6668 27.0019 34.8001 28.4519 34.3168 29.7686C33.8334 31.0852 32.7834 32.0852 31.4668 32.5186C27.8001 33.7519 23.9168 34.3686 20.0334 34.3686ZM20.0334 6.26855C15.2168 6.26855 11.2834 10.1852 11.2834 15.0186V19.8352C11.2834 21.0519 10.7834 22.8519 10.1668 23.9019L8.25011 27.0852C7.88344 27.7019 7.78344 28.3519 8.00011 28.9019C8.20011 29.4686 8.70011 29.9019 9.38344 30.1352C16.3501 32.4686 23.7334 32.4686 30.7001 30.1352C31.3001 29.9352 31.7668 29.4852 31.9834 28.8852C32.2001 28.2852 32.1501 27.6352 31.8168 27.0852L29.9001 23.9019C29.2668 22.8186 28.7834 21.0352 28.7834 19.8186V15.0186C28.7834 10.1852 24.8668 6.26855 20.0334 6.26855Z" fill="#048EAB"/>
                                <path d="M23.1334 6.71914C23.0168 6.71914 22.9001 6.70247 22.7834 6.66914C22.3001 6.53581 21.8334 6.43581 21.3834 6.36914C19.9668 6.18581 18.6001 6.28581 17.3168 6.66914C16.8501 6.81914 16.3501 6.66914 16.0334 6.31914C15.7168 5.96914 15.6168 5.46914 15.8001 5.01914C16.4834 3.26914 18.1501 2.11914 20.0501 2.11914C21.9501 2.11914 23.6168 3.25247 24.3001 5.01914C24.4668 5.46914 24.3834 5.96914 24.0668 6.31914C23.8168 6.58581 23.4668 6.71914 23.1334 6.71914Z" fill="#048EAB"/>
                                <path d="M20.0334 38.1689C18.3834 38.1689 16.7834 37.5023 15.6168 36.3356C14.4501 35.1689 13.7834 33.5689 13.7834 31.9189H16.2834C16.2834 32.9023 16.6834 33.8689 17.3834 34.5689C18.0834 35.2689 19.0501 35.6689 20.0334 35.6689C22.1001 35.6689 23.7834 33.9856 23.7834 31.9189H26.2834C26.2834 35.3689 23.4834 38.1689 20.0334 38.1689Z" fill="#048EAB"/>
                            </svg>
                        </span>
                        <div className='Notifications-content-info'>
                            <h4>Welcome in yas adventures</h4>
                        </div>
                    </div>
                </div> */}
            </div>
            
        </div>
    )
}

export default Notifications