import React, { useEffect, useState } from 'react';
import './profile.css';
import ProfileImg from '../../Images/dummy.png'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../Common/AntdTextField';
import { Button, Form } from 'antd';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import getProfileDataApi from '../../apis/auth/getProfileDataApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import Cookies from 'js-cookie';
import updateProfileApi from '../../apis/auth/updateProfileApi';
import { useTranslation } from 'react-i18next';
import deleteAccountApi from '../../apis/auth/deleteAccountApi';
import UploadProfileImage from '../UploadProfileImage/UploadProfileImage';

let Regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


const Profile = () => {
    const { t } = useTranslation();
    const lang = localStorage.getItem("lang");
    const [profileData , setProfileData] = React.useState()
    const [prevCount , setPrevCount] = useState(0)
    const customApiRequest = useCustomApiRequest()
    const [modelEditImg , setModelEditImg] = useState(false)
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const schema = Yup.object().shape({
        email: Yup.string()
            .matches(Regex , t('emailValidateCorrect'))
            .optional(t('emailValidate'))
            .email(t('emailValidateCorrect')),
        name: Yup.string().required('أدخل الإسم'),
    });
    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            name: '',
            email: '',
        }
    });

    useEffect(() => {
        customApiRequest(
            getProfileDataApi(user?.token),
            (res) => {
                if (res?.data?.status) {
                    setProfileData(res?.data?.data?.user);
                } 
            },  
            (error) => {

            }
        )
    } , [prevCount])

    const onSubmit = (data) => {
        customApiRequest(
            updateProfileApi(user?.token , data , lang),
            (res) => {
                if (res?.status && res?.data?.status ) {
                    setPrevCount((prev => prev + 1))
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (!res?.data.status) {
                    toast.error(res?.data?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {

            }
        )
    }

    const handleDeleteAcc = () => {
        customApiRequest(
            deleteAccountApi(user?.token , lang),
            (res) => {
                if (res?.status && res?.data ) {
                    Cookies.remove('user')
                    Navigate('/')
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (!res?.data.status) {
                    toast.error(res?.data?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {

            }
        )
    }
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    const [form] = Form.useForm();
    return (
        <>
            <div className='Profile'>
                <div className='Profile-content'>
                    <div className="profile-header">
                        <div className="profile-header-content">
                            <div>
                                <img className='profile-img' src={profileData?.image} alt="profile" />
                            </div>
                            <div>
                                <h3>{profileData?.name}</h3>
                                <span>{profileData?.email}</span>
                            </div>
                        </div>
                        <div className="profile-header-edit">
                            <Button onClick={() => setModelEditImg(true)}>{t('Edit your image')}</Button>
                        </div>
                    </div>
                    <hr />
                    <div className="profile-form">
                        <Form
                            className="register-form"
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit(onSubmit)}
                        >
                            <div className="form-body-profile">
                                <AntdTextField
                                    className="form-text-field"
                                    setValue={setValue}
                                    name="name"
                                    type="text"
                                    placeholder={t("name")}
                                    label={t("name")}
                                    errorMsg={errors?.name?.message}
                                    validateStatus={errors?.name ? 'error' : ''}
                                    // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                    control={control}
                                    value={profileData?.name}
                                    defaultValue={profileData?.name}
                                />
                                {/* <AntdTextField
                                    className="form-text-field"
                                    name="country"
                                    type="text"
                                    placeholder={'Country...'}
                                    label="Country"
                                    errorMsg={errors?.country?.message}
                                    validateStatus={errors?.country ? 'error' : ''}
                                    // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                    control={control}
                                    value={profileData?.country}
                                /> */}
                                {/* <AntdTextField
                                    className="form-text-field"
                                    name="phone"
                                    type="tel"
                                    placeholder={t("phone")}
                                    label={t("phone")}
                                    errorMsg={errors?.phone?.message}
                                    validateStatus={errors?.phone ? 'error' : ''}
                                    // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                    control={control}
                                    value={profileData?.phone}
                                /> */}
                                <AntdTextField
                                    className="form-text-field"
                                    setValue={setValue}
                                    name="email"
                                    type="email"
                                    placeholder={t("Email us")}
                                    label={t("Email us")}
                                    errorMsg={errors?.email?.message}
                                    validateStatus={errors?.email ? 'error' : ''}
                                    // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                    control={control}
                                    value={profileData?.email}
                                    defaultValue={profileData?.email}
                                />

                                <Button
                                    className="profile-btn"
                                    htmlType="submit"
                                    type="primary"
                                    // icon={<LoginOutlined />}
                                    // loading={isSubmitting}
                                    // loading={isLoadingSignin}
                                >
                                    {t('save-updates')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <hr />
                    <div className="profile-footer">
                        <div className="profile-delete-account">
                            <h4>{t('Delete Your Account')}</h4>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p> */}
                        </div>
                        <Button onClick={() => handleDeleteAcc()}>{t('Delete')}</Button>
                    </div>
                </div>
            </div>
            {modelEditImg && <UploadProfileImage modelEditImg={modelEditImg} setModelEditImg={setModelEditImg} prevCount={prevCount} setPrevCount={setPrevCount}/>}
        </>
    )
}

export default Profile