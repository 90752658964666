import React, { useEffect } from 'react'
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest'
import { toast } from 'react-toastify'
import getServicesApi from '../../apis/Services/getServicesApi'
import { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import ProductCard from '../Card/ProductCard'
import './Gallery.css'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'
import Services from '../Services/Services'
const Gallery = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    const [services , setServices] = useState();
    useEffect(() => {
        customApiRequest(
            getServicesApi(lang),
            (res) => {
                if (res?.status && res?.data?.data?.ads) {
                    setServices(res?.data?.data?.ads)
                }
            }
        )
    } , [lang])

    return (
        <div className='Gallery-container'>
            <Services />
        </div>
    )
}

export default Gallery