import { Modal, Spin } from 'antd'
import Search from 'antd/es/input/Search';
import React from 'react'
import { useState } from 'react';
import './search.css'
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import ProductCard from '../Card/ProductCard';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import Cookies from 'js-cookie';
import { SearchApi } from '../../apis/SearchApi';
import { useEffect } from 'react';
const SearchComp = (props) => {
    const { t } = useTranslation();
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const lang = localStorage.getItem("lang");
    const [getAllResults , setGetAllResults] = useState();
    const [searchName , setSearchName] = useState();
    const customApiRequest = useCustomApiRequest();
    const searchSubmit = () => {
        customApiRequest(
            SearchApi(user?.token , searchName , lang),
            (res) => {
                if (res?.status && res?.data?.data?.ads) {
                    setGetAllResults(res?.data?.data?.ads)
                }
            }
        )
    }
    
    return (
        <div className='Search-content'>
            <Modal
                // width={'100%'}
                className='search-modal'
                title={t('search-title')}
                centered
                open={props.searchModalOpen}
                onOk={() => props.setSearchModalOpen(false)}
                onCancel={() => props.setSearchModalOpen(false)}
            >
                <Search className='search-input' placeholder={t("search")} enterButton onSearch={() => searchSubmit()} onChange={(e) => setSearchName(e.target.value)}/>
                <div className='search-content'>
                    {getAllResults?.length && <h2>{t('search-results')}</h2>}
                    <Grid container spacing={3}>
                        {
                            getAllResults?.length ? getAllResults?.map((fav , i) => (
                                <Grid key={i} item lg={3} md={6} xs={6} sm={12}>
                                    <ProductCard product={{...fav}} closeModal={props.setSearchModalOpen}/>
                                </Grid>
                            )) 
                            :
                            <>
                                <div className="result-loading">
                                    {
                                    getAllResults?.length ? 
                                    <Spin tip="Loading" size="large"></Spin>
                                    :
                                    <div><h3>{t('no-results')}</h3></div>
                                    }
                                </div>
                            </> 
                        }
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default SearchComp