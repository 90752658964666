import axios from 'axios';

const getTourApi = async (category_id , lang) => {
    let url;
    if (category_id) {
        url = `/getAdsByCategory?category_id=${category_id}`
    } else {
        url = `/getAdsByCategory`
    }
    try {
        const res = await axios.get( url , {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            lang: lang
        }
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default getTourApi;