import axios from 'axios';

export const forgetPasswordApi = async (email , lang) => {
  // const { email } = values;
  try {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('type', 'reset');
    // formData.append('otp', otp);
    const res = await axios.post('/forgetPassword', formData, {
      headers: {
        lang: lang
      }
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
