import axios from 'axios';

const deleteAccountApi = async (token , lang) => {
  try {
    const Res = await axios.post('/deleteAccount', {
      headers: {
        lang: lang,
        'Content-Type': 'multipart/form-data',
        Authorization: token
      }
    });
    return Res;
  } catch (error) {
    console.log(error);
  }
};

export default deleteAccountApi;
