import React, { useEffect, useState } from 'react';
import './yass.css';
import * as Yup from 'yup';
import AppleIcon from '../../Images/Icon.png'
import PlayIcon from '../../Images/Icon2.png'
import OTPInput from "otp-input-react";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { checkPassCodeApi } from '../../apis/yassApi/checkPassCodeApi';
import Cookies from 'js-cookie';
import { Button, Form } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdTextField from '../../Common/AntdTextField';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { addPassCodeApi } from '../../apis/yassApi/addPassCodeApi';
import getResturantsApi from '../../apis/yassApi/getResturantsApi';
import AntdSelectOption from '../../Common/AntdSelectOption';


// const schema = Yup.object().shape({
//     // coupon: Yup.string().required('ادخل الباس كود'),
//     name: Yup.string().required(enterName),
//     phone: Yup.number().required('أدخل رقم الهاتف'),
//     employee_name: Yup.string().required('أدخل إسم الموظف'),
//     pass_id: Yup.string().required('أدخل المطعم'),
// });
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const YassPass = () => {
    const {t} = useTranslation()
    const schema = Yup.object().shape({
        // coupon: Yup.string().required('ادخل الباس كود'),
        name: Yup.string().required(t('nameValidate')).max(30),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').typeError(t('phoneValidate')).min(8, t('phoneValidateMin')).max(15, t('phoneValidateMax')),
        employee_name: Yup.string().required(t('empValidate')),
        pass_id: Yup.string().required(t('restValidate')),
    });
    const lang = localStorage.getItem("lang");
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const [coupon, setCoupon] = useState();
    const [secondCoupon, setSecondCoupon] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [unChecked, setUnChecked] = useState(false);

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            name: '',
            phone: '',
            employee_name: '',
            subjecpass_idet: '',
        }
    });
    const [restaurants , setRestaurants] = useState();

    useEffect(() => {
        customApiRequest(
            getResturantsApi(user?.token , coupon , lang),
            (res) => {
                if (res?.status && res?.data?.data) {
                    setRestaurants(res?.data?.data?.resturants)
                }
            }
        )
    } , [coupon])


    const checkPassCode = async () => {
        try{
            const res = await checkPassCodeApi(user?.token , {coupon : coupon});
            if(res?.data?.status) {
                setIsChecked(true)
                toast.success(res?.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if (!res?.data?.status) {
                setUnChecked(true)
                toast.error(res?.data?.message, {
                    position: "bottom-left",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch(error) {
            throw error
        }
    };
    const customApiRequest = useCustomApiRequest()
    const onSubmit = (data) => {
        customApiRequest(
            addPassCodeApi(user?.token , {...data , coupon : secondCoupon} , lang),
            (res) => {
                
                if (res?.data?.status && res?.data) {
                    // setIsChecked(false)
                    // setUnChecked(false)
                    // reset()
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    window.location.reload()
                } else if (!res?.data.status) {
                    toast.error(res?.data?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {

            }
        )
    }
    const [form] = Form.useForm();
    return (
        <div className='Yass-pass'>
            <div className="Yass-pass-box">
                <div className="yass-pass-check">
                    <h5>{t('Yass Pass CASHER')}</h5>
                    <h2>{t('Yass Pass Code Checker')}</h2>
                    <OTPInput
                        className='otp-verification-content-form-input'
                        value={coupon}
                        onChange={setCoupon}
                        autoFocus
                        OTPLength={10}
                        otpType="text"
                        disabled={false}
                        // secure
                    />
                    {!isChecked && !unChecked &&<Button className='check-btn' onClick={() => checkPassCode()}>{t('Check')}</Button>}
                    {isChecked &&
                    <div className='checked-btn'>
                        <svg width="35" height="35" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.2477 54.4955C20.0211 54.4955 13.0906 51.6248 7.98066 46.5148C2.87073 41.4049 0 34.4743 0 27.2478C0 20.0213 2.87073 13.0907 7.98066 7.9808C13.0906 2.87087 20.0211 0.000146378 27.2477 0.00014627C31.4939 -0.0139105 35.6823 0.985379 39.4648 2.91501C39.6878 3.02819 39.8863 3.18417 40.0491 3.37407C40.2118 3.56398 40.3355 3.78407 40.4132 4.02179C40.4909 4.25951 40.5209 4.5102 40.5017 4.75954C40.4825 5.00889 40.4144 5.25201 40.3012 5.47503C40.188 5.69804 40.032 5.89658 39.8421 6.05931C39.6522 6.22204 39.4322 6.34577 39.1944 6.42344C38.9567 6.50111 38.706 6.5312 38.4567 6.51199C38.2073 6.49278 37.9642 6.42464 37.7412 6.31147C34.4965 4.63919 30.8979 3.76996 27.2477 3.7768C22.6116 3.7768 18.0797 5.15123 14.2246 7.72635C10.3695 10.3015 7.36442 13.9617 5.58913 18.2444C3.81384 22.527 3.34806 27.2399 4.25067 31.7872C5.15327 36.3345 7.38374 40.5122 10.6601 43.7921C13.9365 47.072 18.1118 49.307 22.6581 50.2145C27.2045 51.1221 31.9178 50.6614 36.2024 48.8907C40.487 47.12 44.1504 44.1189 46.7297 40.2666C49.309 36.4143 50.6883 31.8839 50.6933 27.2478C50.7066 27.0368 50.7066 26.8252 50.6933 26.6142C50.6463 26.11 50.8014 25.6078 51.1247 25.218C51.4479 24.8282 51.9127 24.5828 52.4169 24.5357C52.9211 24.4887 53.4233 24.6438 53.8131 24.9671C54.2029 25.2903 54.4483 25.7551 54.4953 26.2593V27.2478C54.4886 34.4723 51.6158 41.3989 46.5073 46.5074C41.3988 51.6159 34.4721 54.4888 27.2477 54.4955Z" fill="white"/>
                            <path d="M26.7912 35.891C26.5449 35.8947 26.3004 35.847 26.0735 35.751C25.8466 35.655 25.6421 35.5128 25.4732 35.3334L13.2308 23.3191C12.8748 22.9627 12.6748 22.4795 12.6748 21.9757C12.6748 21.4719 12.8748 20.9888 13.2308 20.6323C13.5946 20.2808 14.0808 20.0843 14.5868 20.0843C15.0928 20.0843 15.579 20.2808 15.9429 20.6323L26.8166 31.354L51.2761 7.29999C51.64 6.94845 52.1262 6.75195 52.6322 6.75195C53.1381 6.75195 53.6243 6.94845 53.9882 7.29999C54.3442 7.65643 54.5442 8.1396 54.5442 8.64337C54.5442 9.14713 54.3442 9.6303 53.9882 9.98674L28.1853 35.3334C28.0039 35.5174 27.7864 35.6618 27.5465 35.7578C27.3066 35.8538 27.0495 35.8991 26.7912 35.891Z" fill="white"/>
                        </svg>
                        {t('You can use the code')}
                    </div>}
                    {unChecked &&
                    <div className='unchecked-btn'>
                        <svg width="34" height="34" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1359_3909)">
                            <path d="M27 54C12.112 54 0 41.888 0 27C0 12.112 12.112 0 27 0C41.888 0 54 12.112 54 27C54 41.888 41.888 54 27 54ZM27 3.85699C14.2391 3.85699 3.85699 14.2391 3.85699 27C3.85699 39.7609 14.2391 50.143 27 50.143C39.7609 50.143 50.143 39.7609 50.143 27C50.143 14.2391 39.7609 3.85699 27 3.85699Z" fill="white"/>
                            <path d="M36.6421 38.5714C36.1485 38.5714 35.6549 38.3832 35.2784 38.0061L15.9929 18.7206C15.2393 17.9671 15.2393 16.7468 15.9929 15.9937C16.746 15.2406 17.9667 15.2402 18.7198 15.9937L38.0053 35.2792C38.7589 36.0328 38.7589 37.2531 38.0053 38.0061C37.8266 38.1856 37.6141 38.3279 37.3802 38.425C37.1462 38.522 36.8954 38.5718 36.6421 38.5714Z" fill="white"/>
                            <path d="M17.3561 38.5716C16.8625 38.5716 16.3694 38.3833 15.9929 38.0063C15.2393 37.2532 15.2393 36.0325 15.9929 35.2794L35.2784 15.9939C36.032 15.2403 37.2523 15.2403 38.0053 15.9939C38.7585 16.7469 38.7589 17.9676 38.0053 18.7208L18.7198 38.0063C18.541 38.1858 18.3285 38.3281 18.0944 38.4251C17.8604 38.5222 17.6095 38.5719 17.3561 38.5716Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1359_3909">
                            <rect width="54" height="54" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        {t('You can not use the code')}
                    </div>}
                </div>
                
                {/* <h2>You can only use Yas Pass by downloading the mobile application</h2>
                <div className="Yass-pass-btn-group">
                    <a href='/'>
                        <img src={AppleIcon} alt="" />
                        <div>
                            <p>Download on the</p>
                            <p>Apple Store</p>
                        </div>
                    </a>
                    <a href='/'>
                        <img src={PlayIcon} alt="" />
                        <div>
                            <p>Download on the</p>
                            <p>Google play</p>
                        </div>
                    </a>
                </div> */}
            </div>
            {
                isChecked &&
                <div className="Yass-pass-box">
                    <div className="yass-pass-use">
                        <h2>Yass Pass use</h2>
                        <Form
                            className="Yass-pass-form"
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit(onSubmit)}
                        >
                            <div className="form-body">
                                <AntdTextField
                                    className="form-text-field"
                                    name="name"
                                    type="text"
                                    placeholder={t("name")}
                                    errorMsg={errors?.name?.message}
                                    validateStatus={errors?.name ? 'error' : ''}
                                    // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                    control={control}
                                />
                                <AntdTextField
                                    className="form-text-field"
                                    name="employee_name"
                                    type="text"
                                    placeholder={t("employee_name")}
                                    errorMsg={errors?.employee_name?.message}
                                    validateStatus={errors?.employee_name ? 'error' : ''}
                                    // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                    control={control}
                                />
                                <AntdTextField
                                    className="form-text-field"
                                    name="phone"
                                    type="tel"
                                    placeholder={t("phone")}
                                    errorMsg={errors?.phone?.message}
                                    validateStatus={errors?.phone ? 'error' : ''}
                                    // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                    control={control}
                                />
                                <AntdSelectOption
                                    className='select-pass'
                                    name='pass_id'
                                    errorMsg={errors?.pass_id && errors.pass_id.message}
                                    validateStatus={errors?.pass_id ? 'error' : ''}
                                    control={control}
                                    setValue={setValue}
                                    placeholder={t("restaurant_name")}
                                    options={
                                        restaurants?.length > 0 &&
                                        restaurants.map((role) => ({
                                        title: role.name,
                                        value: String(role.id)
                                        }))
                                    }
                                    showSearch={true}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    formClassName="outgoing-invoice-form"
                                />

                                <h3 className='pass-title'>Yass Pass code</h3>
                                <OTPInput
                                    name='coupon'
                                    className='otp-verification-content-form-input'
                                    value={secondCoupon}
                                    onChange={setSecondCoupon}
                                    autoFocus
                                    OTPLength={10}
                                    otpType="text"
                                    disabled={false}
                                    // secure
                                />
                            </div>
                            <Button
                                className="yass-btn"
                                htmlType="submit"
                                type="primary"
                            >
                                {t('send')}
                            </Button>
                        </Form>
                    </div>
                </div>
            }
        </div>
    )
}

export default YassPass