import axios from 'axios';

export const LoginApi = async (values, lang) => {
  try {
    const signInRes = await axios.post('/login', values, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        lang: lang
      }
    });
    return signInRes;
  } catch (error) {
    throw error;
  }
};
