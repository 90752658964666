import React from 'react'
import BookingImg from '../../Images/Rectangle.png'
import { Button } from 'antd'
import './bookings.css'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest'
import { useEffect } from 'react'
import { useState } from 'react'
import getOrdersApi from '../../apis/orders/getOrdersApi'
const Bookings = () => {
    const {t} = useTranslation();
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))

    const customApiRequest = useCustomApiRequest();
    const lang = localStorage.getItem("lang");
    const [orders , getOrders] = useState()
    useEffect(() => {
        customApiRequest(
            getOrdersApi(user?.token , lang),
            (res) => {
                if (res?.status && res?.data?.data?.orders) {
                    getOrders(res?.data?.data?.orders)
                }
            }
        )
    } , [])
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='Bookings'>
            <div className="Bookings-content">
                {
                    orders?.length && orders?.map((order) => (
                        <div className="Booking-card">
                            <div>
                                <div className="Booking-card-img">
                                    <img src={order?.sellerImage} alt="BookingImg" />
                                </div>
                                <div className="Booking-card-info">
                                    <h3>{order?.name}</h3>
                                    <p>{order?.desc}</p>
                                    <div className="booking-card-salary">
                                        <h4 className='card-salary'>{order?.price}</h4> 
                                        <span className=''>{t('Qar')}</span> 
                                        <h6 className='for-person'> / {t('Total price')}</h6>
                                    </div>
                                    <span>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z" fill="#767676"/>
                                            <path d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z" fill="#767676"/>
                                            <path d="M6.375 10.8752C6.2775 10.8752 6.18 10.8527 6.09 10.8152C5.9925 10.7777 5.9175 10.7252 5.8425 10.6577C5.7075 10.5152 5.625 10.3277 5.625 10.1252C5.625 10.0277 5.6475 9.93023 5.685 9.84023C5.7225 9.75023 5.775 9.66774 5.8425 9.59274C5.9175 9.52524 5.9925 9.47272 6.09 9.43522C6.36 9.32272 6.6975 9.38274 6.9075 9.59274C7.0425 9.73524 7.125 9.93023 7.125 10.1252C7.125 10.1702 7.1175 10.2227 7.11 10.2752C7.1025 10.3202 7.0875 10.3652 7.065 10.4102C7.05 10.4552 7.0275 10.5002 6.9975 10.5452C6.975 10.5827 6.9375 10.6202 6.9075 10.6577C6.765 10.7927 6.57 10.8752 6.375 10.8752Z" fill="#767676"/>
                                            <path d="M9 10.8751C8.9025 10.8751 8.805 10.8527 8.715 10.8152C8.6175 10.7777 8.5425 10.7251 8.4675 10.6576C8.3325 10.5151 8.25 10.3276 8.25 10.1251C8.25 10.0276 8.2725 9.93015 8.31 9.84015C8.3475 9.75015 8.4 9.66766 8.4675 9.59266C8.5425 9.52516 8.6175 9.47264 8.715 9.43514C8.985 9.31514 9.3225 9.38266 9.5325 9.59266C9.6675 9.73516 9.75 9.93015 9.75 10.1251C9.75 10.1701 9.7425 10.2227 9.735 10.2752C9.7275 10.3202 9.7125 10.3652 9.69 10.4102C9.675 10.4552 9.6525 10.5001 9.6225 10.5451C9.6 10.5826 9.5625 10.6201 9.5325 10.6576C9.39 10.7926 9.195 10.8751 9 10.8751Z" fill="#767676"/>
                                            <path d="M11.625 10.8751C11.5275 10.8751 11.43 10.8527 11.34 10.8152C11.2425 10.7777 11.1675 10.7251 11.0925 10.6576C11.0625 10.6201 11.0325 10.5826 11.0025 10.5451C10.9725 10.5001 10.95 10.4552 10.935 10.4102C10.9125 10.3652 10.8975 10.3202 10.89 10.2752C10.8825 10.2227 10.875 10.1701 10.875 10.1251C10.875 9.93015 10.9575 9.73516 11.0925 9.59266C11.1675 9.52516 11.2425 9.47264 11.34 9.43514C11.6175 9.31514 11.9475 9.38266 12.1575 9.59266C12.2925 9.73516 12.375 9.93015 12.375 10.1251C12.375 10.1701 12.3675 10.2227 12.36 10.2752C12.3525 10.3202 12.3375 10.3652 12.315 10.4102C12.3 10.4552 12.2775 10.5001 12.2475 10.5451C12.225 10.5826 12.1875 10.6201 12.1575 10.6576C12.015 10.7926 11.82 10.8751 11.625 10.8751Z" fill="#767676"/>
                                            <path d="M6.375 13.4999C6.2775 13.4999 6.18 13.4774 6.09 13.4399C6 13.4024 5.9175 13.3499 5.8425 13.2824C5.7075 13.1399 5.625 12.9449 5.625 12.7499C5.625 12.6524 5.6475 12.5549 5.685 12.4649C5.7225 12.3674 5.775 12.2849 5.8425 12.2174C6.12 11.9399 6.63 11.9399 6.9075 12.2174C7.0425 12.3599 7.125 12.5549 7.125 12.7499C7.125 12.9449 7.0425 13.1399 6.9075 13.2824C6.765 13.4174 6.57 13.4999 6.375 13.4999Z" fill="#767676"/>
                                            <path d="M9 13.4999C8.805 13.4999 8.61 13.4174 8.4675 13.2824C8.3325 13.1399 8.25 12.9449 8.25 12.7499C8.25 12.6524 8.2725 12.5549 8.31 12.4649C8.3475 12.3674 8.4 12.2849 8.4675 12.2174C8.745 11.9399 9.255 11.9399 9.5325 12.2174C9.6 12.2849 9.6525 12.3674 9.69 12.4649C9.7275 12.5549 9.75 12.6524 9.75 12.7499C9.75 12.9449 9.6675 13.1399 9.5325 13.2824C9.39 13.4174 9.195 13.4999 9 13.4999Z" fill="#767676"/>
                                            <path d="M11.625 13.5002C11.43 13.5002 11.235 13.4177 11.0925 13.2827C11.025 13.2152 10.9725 13.1327 10.935 13.0352C10.8975 12.9452 10.875 12.8477 10.875 12.7502C10.875 12.6527 10.8975 12.5552 10.935 12.4652C10.9725 12.3677 11.025 12.2852 11.0925 12.2177C11.265 12.0452 11.5275 11.9627 11.7675 12.0152C11.82 12.0227 11.865 12.0377 11.91 12.0602C11.955 12.0752 12 12.0977 12.045 12.1277C12.0825 12.1502 12.12 12.1877 12.1575 12.2177C12.2925 12.3602 12.375 12.5552 12.375 12.7502C12.375 12.9452 12.2925 13.1402 12.1575 13.2827C12.015 13.4177 11.82 13.5002 11.625 13.5002Z" fill="#767676"/>
                                            <path d="M15.375 7.37988H2.625C2.3175 7.37988 2.0625 7.12488 2.0625 6.81738C2.0625 6.50988 2.3175 6.25488 2.625 6.25488H15.375C15.6825 6.25488 15.9375 6.50988 15.9375 6.81738C15.9375 7.12488 15.6825 7.37988 15.375 7.37988Z" fill="#767676"/>
                                            <path d="M12 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.75C16.3125 15.4875 14.7375 17.0625 12 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H12C14.145 15.9375 15.1875 14.895 15.1875 12.75V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z" fill="#767676"/>
                                        </svg>
                                        {order?.date}
                                    </span>
                                </div>
                            </div>
                            <div className='Booking-card-right'>
                                <div className="Booking-card-order">
                                    <h2>ORDER #{order?.orderId}</h2>
                                </div>
                                <div className="Booking-card-btn">
                                    <Button>Download Ticket</Button>
                                </div>
                            </div>
                            <span className='Booking-card-sun'>
                                <svg width="74" height="110" viewBox="0 0 94 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M51.949 87.9147C51.949 60.9869 28.6871 39.1576 0.00250918 39.1576L0.00251124 62.7282C14.8245 62.7282 26.8378 73.9981 26.8378 87.9147C26.8378 101.817 14.8245 113.101 0.00251564 113.101L0.0025177 136.672C28.6871 136.672 51.949 114.843 51.949 87.9147Z" fill="#FEAE17"/>
                                    <path d="M26.8389 87.9124C26.8389 74.0096 14.8255 62.7258 0.00356615 62.7258L0.00356835 87.8845L0.00357056 113.085C14.8256 113.099 26.8389 101.829 26.8389 87.9124Z" fill="#004F6C"/>
                                    <path d="M-0.000203282 -1.22331e-05L-0.000201326 22.3725C4.37965 22.3725 8.63436 22.8044 12.7917 23.5288L-0.000203282 -1.22331e-05Z" fill="#FF9C54"/>
                                    <path d="M14.3943 26.4486C9.75025 25.4874 4.95326 24.958 0.00332568 24.958L0.00332619 30.7671C6.31588 30.7671 12.406 31.6726 18.1345 33.3442L14.3943 26.4486Z" fill="#004F6C"/>
                                    <path d="M65.4877 74.4126L58.1462 70.8882C59.926 76.2654 60.8993 81.9909 60.8993 87.9114C60.8993 93.8458 59.9399 99.5574 58.1462 104.935L65.4877 101.41C66.5027 97.0498 67.0867 92.5503 67.0867 87.9114C67.0867 83.2725 66.5027 78.7589 65.4877 74.4126Z" fill="#004F6C"/>
                                    <path d="M68.6046 75.9075C69.3832 79.808 69.8282 83.8061 69.8282 87.9156C69.8282 92.0252 69.3693 96.0233 68.6046 99.9238L93.6602 87.9156L68.6046 75.9075Z" fill="#FF9C54"/>
                                    <path d="M36.0831 34.921L28.2411 37.3031C39.1977 42.6943 48.1798 51.1223 53.9223 61.417L56.4668 54.0616C51.2249 46.3719 44.2588 39.8385 36.0831 34.921Z" fill="#004F6C"/>
                                    <path d="M57.5615 50.874L66.2378 25.7432L39.472 33.8787C46.5493 38.4618 52.6811 44.2291 57.5615 50.874Z" fill="#FF9C54"/>
                                    <path d="M28.2411 138.525L36.0831 140.907C44.2727 135.99 51.2249 129.456 56.4668 121.767L53.9223 114.411C48.1659 124.706 39.1977 133.134 28.2411 138.525Z" fill="#004F6C"/>
                                    <path d="M39.4645 141.948L66.2302 150.084L57.5539 124.953C52.6874 131.598 46.5557 137.365 39.4645 141.948Z" fill="#FF9C54"/>
                                    <path d="M4.09607e-05 153.47L4.29153e-05 175.829L12.792 152.3C8.64851 153.039 4.3799 153.47 4.09607e-05 153.47Z" fill="#FF9C54"/>
                                    <path d="M18.1509 142.494C12.4223 144.166 6.33221 145.071 0.0196643 145.071L0.0196648 150.88C4.95569 150.88 9.76659 150.337 14.4106 149.39L18.1509 142.494Z" fill="#004F6C"/>
                                </svg>
                            </span>
                            {/* <div className="Booking-card-tree">
                                <svg width="63" height="221" viewBox="0 0 63 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.1563 65.4119L51.4865 29.2261H10.8359L31.1563 65.4119Z" fill="#FFC800"/>
                                    <path d="M31.1563 51.8065L51.4865 15.6206H10.8359L31.1563 51.8065Z" fill="#007699"/>
                                    <path d="M31.1563 36.176L51.4865 0H10.8359L31.1563 36.176Z" fill="#004F6C"/>
                                    <path d="M35.0954 81.6279H27.5403V240.173H35.0954V81.6279Z" fill="#0087A7"/>
                                    <path d="M31.3195 121.028L0 75.4147L2.94356 73.5371L31.3195 114.864L59.6856 73.5371L62.639 75.4147L31.3195 121.028Z" fill="#0087A7"/>
                                    <path d="M31.3195 87.7008L0 42.0777L2.94356 40.21L31.3195 81.5371L59.6856 40.21L62.639 42.0777L31.3195 87.7008Z" fill="#0087A7"/>
                                </svg>
                            </div> */}
                        </div>
                    ))
                }
                {/* <div className="Booking-card">
                    <div>
                        <div className="Booking-card-img">
                            <img src={BookingImg} alt="BookingImg" />
                        </div>
                        <div className="Booking-card-info">
                            <h3>Cruise</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit</p>
                            <div className="booking-card-salary">
                                <span className=''>QAR</span> 
                                <h4 className='card-salary'>412,00</h4> 
                                <h6 className='for-person'> / Total</h6>
                            </div>
                            <span>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z" fill="#767676"/>
                                    <path d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z" fill="#767676"/>
                                    <path d="M6.375 10.8752C6.2775 10.8752 6.18 10.8527 6.09 10.8152C5.9925 10.7777 5.9175 10.7252 5.8425 10.6577C5.7075 10.5152 5.625 10.3277 5.625 10.1252C5.625 10.0277 5.6475 9.93023 5.685 9.84023C5.7225 9.75023 5.775 9.66774 5.8425 9.59274C5.9175 9.52524 5.9925 9.47272 6.09 9.43522C6.36 9.32272 6.6975 9.38274 6.9075 9.59274C7.0425 9.73524 7.125 9.93023 7.125 10.1252C7.125 10.1702 7.1175 10.2227 7.11 10.2752C7.1025 10.3202 7.0875 10.3652 7.065 10.4102C7.05 10.4552 7.0275 10.5002 6.9975 10.5452C6.975 10.5827 6.9375 10.6202 6.9075 10.6577C6.765 10.7927 6.57 10.8752 6.375 10.8752Z" fill="#767676"/>
                                    <path d="M9 10.8751C8.9025 10.8751 8.805 10.8527 8.715 10.8152C8.6175 10.7777 8.5425 10.7251 8.4675 10.6576C8.3325 10.5151 8.25 10.3276 8.25 10.1251C8.25 10.0276 8.2725 9.93015 8.31 9.84015C8.3475 9.75015 8.4 9.66766 8.4675 9.59266C8.5425 9.52516 8.6175 9.47264 8.715 9.43514C8.985 9.31514 9.3225 9.38266 9.5325 9.59266C9.6675 9.73516 9.75 9.93015 9.75 10.1251C9.75 10.1701 9.7425 10.2227 9.735 10.2752C9.7275 10.3202 9.7125 10.3652 9.69 10.4102C9.675 10.4552 9.6525 10.5001 9.6225 10.5451C9.6 10.5826 9.5625 10.6201 9.5325 10.6576C9.39 10.7926 9.195 10.8751 9 10.8751Z" fill="#767676"/>
                                    <path d="M11.625 10.8751C11.5275 10.8751 11.43 10.8527 11.34 10.8152C11.2425 10.7777 11.1675 10.7251 11.0925 10.6576C11.0625 10.6201 11.0325 10.5826 11.0025 10.5451C10.9725 10.5001 10.95 10.4552 10.935 10.4102C10.9125 10.3652 10.8975 10.3202 10.89 10.2752C10.8825 10.2227 10.875 10.1701 10.875 10.1251C10.875 9.93015 10.9575 9.73516 11.0925 9.59266C11.1675 9.52516 11.2425 9.47264 11.34 9.43514C11.6175 9.31514 11.9475 9.38266 12.1575 9.59266C12.2925 9.73516 12.375 9.93015 12.375 10.1251C12.375 10.1701 12.3675 10.2227 12.36 10.2752C12.3525 10.3202 12.3375 10.3652 12.315 10.4102C12.3 10.4552 12.2775 10.5001 12.2475 10.5451C12.225 10.5826 12.1875 10.6201 12.1575 10.6576C12.015 10.7926 11.82 10.8751 11.625 10.8751Z" fill="#767676"/>
                                    <path d="M6.375 13.4999C6.2775 13.4999 6.18 13.4774 6.09 13.4399C6 13.4024 5.9175 13.3499 5.8425 13.2824C5.7075 13.1399 5.625 12.9449 5.625 12.7499C5.625 12.6524 5.6475 12.5549 5.685 12.4649C5.7225 12.3674 5.775 12.2849 5.8425 12.2174C6.12 11.9399 6.63 11.9399 6.9075 12.2174C7.0425 12.3599 7.125 12.5549 7.125 12.7499C7.125 12.9449 7.0425 13.1399 6.9075 13.2824C6.765 13.4174 6.57 13.4999 6.375 13.4999Z" fill="#767676"/>
                                    <path d="M9 13.4999C8.805 13.4999 8.61 13.4174 8.4675 13.2824C8.3325 13.1399 8.25 12.9449 8.25 12.7499C8.25 12.6524 8.2725 12.5549 8.31 12.4649C8.3475 12.3674 8.4 12.2849 8.4675 12.2174C8.745 11.9399 9.255 11.9399 9.5325 12.2174C9.6 12.2849 9.6525 12.3674 9.69 12.4649C9.7275 12.5549 9.75 12.6524 9.75 12.7499C9.75 12.9449 9.6675 13.1399 9.5325 13.2824C9.39 13.4174 9.195 13.4999 9 13.4999Z" fill="#767676"/>
                                    <path d="M11.625 13.5002C11.43 13.5002 11.235 13.4177 11.0925 13.2827C11.025 13.2152 10.9725 13.1327 10.935 13.0352C10.8975 12.9452 10.875 12.8477 10.875 12.7502C10.875 12.6527 10.8975 12.5552 10.935 12.4652C10.9725 12.3677 11.025 12.2852 11.0925 12.2177C11.265 12.0452 11.5275 11.9627 11.7675 12.0152C11.82 12.0227 11.865 12.0377 11.91 12.0602C11.955 12.0752 12 12.0977 12.045 12.1277C12.0825 12.1502 12.12 12.1877 12.1575 12.2177C12.2925 12.3602 12.375 12.5552 12.375 12.7502C12.375 12.9452 12.2925 13.1402 12.1575 13.2827C12.015 13.4177 11.82 13.5002 11.625 13.5002Z" fill="#767676"/>
                                    <path d="M15.375 7.37988H2.625C2.3175 7.37988 2.0625 7.12488 2.0625 6.81738C2.0625 6.50988 2.3175 6.25488 2.625 6.25488H15.375C15.6825 6.25488 15.9375 6.50988 15.9375 6.81738C15.9375 7.12488 15.6825 7.37988 15.375 7.37988Z" fill="#767676"/>
                                    <path d="M12 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.75C16.3125 15.4875 14.7375 17.0625 12 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H12C14.145 15.9375 15.1875 14.895 15.1875 12.75V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z" fill="#767676"/>
                                </svg>
                                13 Jun 2023
                            </span>
                        </div>
                    </div>
                    <div className='Booking-card-right'>
                        <div className="Booking-card-order">
                            <h2>ORDER #357216</h2>
                        </div>
                        <div className="Booking-card-btn">
                            <Button>Download Ticket</Button>
                        </div>
                    </div>
                    <span className='Booking-card-sun'>
                        <svg width="74" height="110" viewBox="0 0 94 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51.949 87.9147C51.949 60.9869 28.6871 39.1576 0.00250918 39.1576L0.00251124 62.7282C14.8245 62.7282 26.8378 73.9981 26.8378 87.9147C26.8378 101.817 14.8245 113.101 0.00251564 113.101L0.0025177 136.672C28.6871 136.672 51.949 114.843 51.949 87.9147Z" fill="#FEAE17"/>
                            <path d="M26.8389 87.9124C26.8389 74.0096 14.8255 62.7258 0.00356615 62.7258L0.00356835 87.8845L0.00357056 113.085C14.8256 113.099 26.8389 101.829 26.8389 87.9124Z" fill="#004F6C"/>
                            <path d="M-0.000203282 -1.22331e-05L-0.000201326 22.3725C4.37965 22.3725 8.63436 22.8044 12.7917 23.5288L-0.000203282 -1.22331e-05Z" fill="#FF9C54"/>
                            <path d="M14.3943 26.4486C9.75025 25.4874 4.95326 24.958 0.00332568 24.958L0.00332619 30.7671C6.31588 30.7671 12.406 31.6726 18.1345 33.3442L14.3943 26.4486Z" fill="#004F6C"/>
                            <path d="M65.4877 74.4126L58.1462 70.8882C59.926 76.2654 60.8993 81.9909 60.8993 87.9114C60.8993 93.8458 59.9399 99.5574 58.1462 104.935L65.4877 101.41C66.5027 97.0498 67.0867 92.5503 67.0867 87.9114C67.0867 83.2725 66.5027 78.7589 65.4877 74.4126Z" fill="#004F6C"/>
                            <path d="M68.6046 75.9075C69.3832 79.808 69.8282 83.8061 69.8282 87.9156C69.8282 92.0252 69.3693 96.0233 68.6046 99.9238L93.6602 87.9156L68.6046 75.9075Z" fill="#FF9C54"/>
                            <path d="M36.0831 34.921L28.2411 37.3031C39.1977 42.6943 48.1798 51.1223 53.9223 61.417L56.4668 54.0616C51.2249 46.3719 44.2588 39.8385 36.0831 34.921Z" fill="#004F6C"/>
                            <path d="M57.5615 50.874L66.2378 25.7432L39.472 33.8787C46.5493 38.4618 52.6811 44.2291 57.5615 50.874Z" fill="#FF9C54"/>
                            <path d="M28.2411 138.525L36.0831 140.907C44.2727 135.99 51.2249 129.456 56.4668 121.767L53.9223 114.411C48.1659 124.706 39.1977 133.134 28.2411 138.525Z" fill="#004F6C"/>
                            <path d="M39.4645 141.948L66.2302 150.084L57.5539 124.953C52.6874 131.598 46.5557 137.365 39.4645 141.948Z" fill="#FF9C54"/>
                            <path d="M4.09607e-05 153.47L4.29153e-05 175.829L12.792 152.3C8.64851 153.039 4.3799 153.47 4.09607e-05 153.47Z" fill="#FF9C54"/>
                            <path d="M18.1509 142.494C12.4223 144.166 6.33221 145.071 0.0196643 145.071L0.0196648 150.88C4.95569 150.88 9.76659 150.337 14.4106 149.39L18.1509 142.494Z" fill="#004F6C"/>
                        </svg>
                    </span>
                    <div className="Booking-card-tree">
                        <svg width="63" height="221" viewBox="0 0 63 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.1563 65.4119L51.4865 29.2261H10.8359L31.1563 65.4119Z" fill="#FFC800"/>
                            <path d="M31.1563 51.8065L51.4865 15.6206H10.8359L31.1563 51.8065Z" fill="#007699"/>
                            <path d="M31.1563 36.176L51.4865 0H10.8359L31.1563 36.176Z" fill="#004F6C"/>
                            <path d="M35.0954 81.6279H27.5403V240.173H35.0954V81.6279Z" fill="#0087A7"/>
                            <path d="M31.3195 121.028L0 75.4147L2.94356 73.5371L31.3195 114.864L59.6856 73.5371L62.639 75.4147L31.3195 121.028Z" fill="#0087A7"/>
                            <path d="M31.3195 87.7008L0 42.0777L2.94356 40.21L31.3195 81.5371L59.6856 40.21L62.639 42.0777L31.3195 87.7008Z" fill="#0087A7"/>
                        </svg>
                    </div>
                </div>
                <div className="Booking-card">
                    <div>
                        <div className="Booking-card-img">
                            <img src={BookingImg} alt="BookingImg" />
                        </div>
                        <div className="Booking-card-info">
                            <h3>Cruise</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit</p>
                            <div className="booking-card-salary">
                                <span className=''>QAR</span> 
                                <h4 className='card-salary'>412,00</h4> 
                                <h6 className='for-person'> / Total</h6>
                            </div>
                            <span>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z" fill="#767676"/>
                                    <path d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z" fill="#767676"/>
                                    <path d="M6.375 10.8752C6.2775 10.8752 6.18 10.8527 6.09 10.8152C5.9925 10.7777 5.9175 10.7252 5.8425 10.6577C5.7075 10.5152 5.625 10.3277 5.625 10.1252C5.625 10.0277 5.6475 9.93023 5.685 9.84023C5.7225 9.75023 5.775 9.66774 5.8425 9.59274C5.9175 9.52524 5.9925 9.47272 6.09 9.43522C6.36 9.32272 6.6975 9.38274 6.9075 9.59274C7.0425 9.73524 7.125 9.93023 7.125 10.1252C7.125 10.1702 7.1175 10.2227 7.11 10.2752C7.1025 10.3202 7.0875 10.3652 7.065 10.4102C7.05 10.4552 7.0275 10.5002 6.9975 10.5452C6.975 10.5827 6.9375 10.6202 6.9075 10.6577C6.765 10.7927 6.57 10.8752 6.375 10.8752Z" fill="#767676"/>
                                    <path d="M9 10.8751C8.9025 10.8751 8.805 10.8527 8.715 10.8152C8.6175 10.7777 8.5425 10.7251 8.4675 10.6576C8.3325 10.5151 8.25 10.3276 8.25 10.1251C8.25 10.0276 8.2725 9.93015 8.31 9.84015C8.3475 9.75015 8.4 9.66766 8.4675 9.59266C8.5425 9.52516 8.6175 9.47264 8.715 9.43514C8.985 9.31514 9.3225 9.38266 9.5325 9.59266C9.6675 9.73516 9.75 9.93015 9.75 10.1251C9.75 10.1701 9.7425 10.2227 9.735 10.2752C9.7275 10.3202 9.7125 10.3652 9.69 10.4102C9.675 10.4552 9.6525 10.5001 9.6225 10.5451C9.6 10.5826 9.5625 10.6201 9.5325 10.6576C9.39 10.7926 9.195 10.8751 9 10.8751Z" fill="#767676"/>
                                    <path d="M11.625 10.8751C11.5275 10.8751 11.43 10.8527 11.34 10.8152C11.2425 10.7777 11.1675 10.7251 11.0925 10.6576C11.0625 10.6201 11.0325 10.5826 11.0025 10.5451C10.9725 10.5001 10.95 10.4552 10.935 10.4102C10.9125 10.3652 10.8975 10.3202 10.89 10.2752C10.8825 10.2227 10.875 10.1701 10.875 10.1251C10.875 9.93015 10.9575 9.73516 11.0925 9.59266C11.1675 9.52516 11.2425 9.47264 11.34 9.43514C11.6175 9.31514 11.9475 9.38266 12.1575 9.59266C12.2925 9.73516 12.375 9.93015 12.375 10.1251C12.375 10.1701 12.3675 10.2227 12.36 10.2752C12.3525 10.3202 12.3375 10.3652 12.315 10.4102C12.3 10.4552 12.2775 10.5001 12.2475 10.5451C12.225 10.5826 12.1875 10.6201 12.1575 10.6576C12.015 10.7926 11.82 10.8751 11.625 10.8751Z" fill="#767676"/>
                                    <path d="M6.375 13.4999C6.2775 13.4999 6.18 13.4774 6.09 13.4399C6 13.4024 5.9175 13.3499 5.8425 13.2824C5.7075 13.1399 5.625 12.9449 5.625 12.7499C5.625 12.6524 5.6475 12.5549 5.685 12.4649C5.7225 12.3674 5.775 12.2849 5.8425 12.2174C6.12 11.9399 6.63 11.9399 6.9075 12.2174C7.0425 12.3599 7.125 12.5549 7.125 12.7499C7.125 12.9449 7.0425 13.1399 6.9075 13.2824C6.765 13.4174 6.57 13.4999 6.375 13.4999Z" fill="#767676"/>
                                    <path d="M9 13.4999C8.805 13.4999 8.61 13.4174 8.4675 13.2824C8.3325 13.1399 8.25 12.9449 8.25 12.7499C8.25 12.6524 8.2725 12.5549 8.31 12.4649C8.3475 12.3674 8.4 12.2849 8.4675 12.2174C8.745 11.9399 9.255 11.9399 9.5325 12.2174C9.6 12.2849 9.6525 12.3674 9.69 12.4649C9.7275 12.5549 9.75 12.6524 9.75 12.7499C9.75 12.9449 9.6675 13.1399 9.5325 13.2824C9.39 13.4174 9.195 13.4999 9 13.4999Z" fill="#767676"/>
                                    <path d="M11.625 13.5002C11.43 13.5002 11.235 13.4177 11.0925 13.2827C11.025 13.2152 10.9725 13.1327 10.935 13.0352C10.8975 12.9452 10.875 12.8477 10.875 12.7502C10.875 12.6527 10.8975 12.5552 10.935 12.4652C10.9725 12.3677 11.025 12.2852 11.0925 12.2177C11.265 12.0452 11.5275 11.9627 11.7675 12.0152C11.82 12.0227 11.865 12.0377 11.91 12.0602C11.955 12.0752 12 12.0977 12.045 12.1277C12.0825 12.1502 12.12 12.1877 12.1575 12.2177C12.2925 12.3602 12.375 12.5552 12.375 12.7502C12.375 12.9452 12.2925 13.1402 12.1575 13.2827C12.015 13.4177 11.82 13.5002 11.625 13.5002Z" fill="#767676"/>
                                    <path d="M15.375 7.37988H2.625C2.3175 7.37988 2.0625 7.12488 2.0625 6.81738C2.0625 6.50988 2.3175 6.25488 2.625 6.25488H15.375C15.6825 6.25488 15.9375 6.50988 15.9375 6.81738C15.9375 7.12488 15.6825 7.37988 15.375 7.37988Z" fill="#767676"/>
                                    <path d="M12 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.75C16.3125 15.4875 14.7375 17.0625 12 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H12C14.145 15.9375 15.1875 14.895 15.1875 12.75V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z" fill="#767676"/>
                                </svg>
                                13 Jun 2023
                            </span>
                        </div>
                    </div>
                    <div className='Booking-card-right'>
                        <div className="Booking-card-order">
                            <h2>ORDER #357216</h2>
                        </div>
                        <div className="Booking-card-btn">
                            <Button>Download Ticket</Button>
                        </div>
                    </div>
                    <span className='Booking-card-sun'>
                        <svg width="74" height="110" viewBox="0 0 94 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51.949 87.9147C51.949 60.9869 28.6871 39.1576 0.00250918 39.1576L0.00251124 62.7282C14.8245 62.7282 26.8378 73.9981 26.8378 87.9147C26.8378 101.817 14.8245 113.101 0.00251564 113.101L0.0025177 136.672C28.6871 136.672 51.949 114.843 51.949 87.9147Z" fill="#FEAE17"/>
                            <path d="M26.8389 87.9124C26.8389 74.0096 14.8255 62.7258 0.00356615 62.7258L0.00356835 87.8845L0.00357056 113.085C14.8256 113.099 26.8389 101.829 26.8389 87.9124Z" fill="#004F6C"/>
                            <path d="M-0.000203282 -1.22331e-05L-0.000201326 22.3725C4.37965 22.3725 8.63436 22.8044 12.7917 23.5288L-0.000203282 -1.22331e-05Z" fill="#FF9C54"/>
                            <path d="M14.3943 26.4486C9.75025 25.4874 4.95326 24.958 0.00332568 24.958L0.00332619 30.7671C6.31588 30.7671 12.406 31.6726 18.1345 33.3442L14.3943 26.4486Z" fill="#004F6C"/>
                            <path d="M65.4877 74.4126L58.1462 70.8882C59.926 76.2654 60.8993 81.9909 60.8993 87.9114C60.8993 93.8458 59.9399 99.5574 58.1462 104.935L65.4877 101.41C66.5027 97.0498 67.0867 92.5503 67.0867 87.9114C67.0867 83.2725 66.5027 78.7589 65.4877 74.4126Z" fill="#004F6C"/>
                            <path d="M68.6046 75.9075C69.3832 79.808 69.8282 83.8061 69.8282 87.9156C69.8282 92.0252 69.3693 96.0233 68.6046 99.9238L93.6602 87.9156L68.6046 75.9075Z" fill="#FF9C54"/>
                            <path d="M36.0831 34.921L28.2411 37.3031C39.1977 42.6943 48.1798 51.1223 53.9223 61.417L56.4668 54.0616C51.2249 46.3719 44.2588 39.8385 36.0831 34.921Z" fill="#004F6C"/>
                            <path d="M57.5615 50.874L66.2378 25.7432L39.472 33.8787C46.5493 38.4618 52.6811 44.2291 57.5615 50.874Z" fill="#FF9C54"/>
                            <path d="M28.2411 138.525L36.0831 140.907C44.2727 135.99 51.2249 129.456 56.4668 121.767L53.9223 114.411C48.1659 124.706 39.1977 133.134 28.2411 138.525Z" fill="#004F6C"/>
                            <path d="M39.4645 141.948L66.2302 150.084L57.5539 124.953C52.6874 131.598 46.5557 137.365 39.4645 141.948Z" fill="#FF9C54"/>
                            <path d="M4.09607e-05 153.47L4.29153e-05 175.829L12.792 152.3C8.64851 153.039 4.3799 153.47 4.09607e-05 153.47Z" fill="#FF9C54"/>
                            <path d="M18.1509 142.494C12.4223 144.166 6.33221 145.071 0.0196643 145.071L0.0196648 150.88C4.95569 150.88 9.76659 150.337 14.4106 149.39L18.1509 142.494Z" fill="#004F6C"/>
                        </svg>
                    </span>
                    <div className="Booking-card-tree">
                        <svg width="63" height="221" viewBox="0 0 63 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.1563 65.4119L51.4865 29.2261H10.8359L31.1563 65.4119Z" fill="#FFC800"/>
                            <path d="M31.1563 51.8065L51.4865 15.6206H10.8359L31.1563 51.8065Z" fill="#007699"/>
                            <path d="M31.1563 36.176L51.4865 0H10.8359L31.1563 36.176Z" fill="#004F6C"/>
                            <path d="M35.0954 81.6279H27.5403V240.173H35.0954V81.6279Z" fill="#0087A7"/>
                            <path d="M31.3195 121.028L0 75.4147L2.94356 73.5371L31.3195 114.864L59.6856 73.5371L62.639 75.4147L31.3195 121.028Z" fill="#0087A7"/>
                            <path d="M31.3195 87.7008L0 42.0777L2.94356 40.21L31.3195 81.5371L59.6856 40.21L62.639 42.0777L31.3195 87.7008Z" fill="#0087A7"/>
                        </svg>
                    </div>
                </div>
                <div className="Booking-card">
                    <div>
                        <div className="Booking-card-img">
                            <img src={BookingImg} alt="BookingImg" />
                        </div>
                        <div className="Booking-card-info">
                            <h3>Cruise</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit</p>
                            <div className="booking-card-salary">
                                <span className=''>QAR</span> 
                                <h4 className='card-salary'>412,00</h4> 
                                <h6 className='for-person'> / Total</h6>
                            </div>
                            <span>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z" fill="#767676"/>
                                    <path d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z" fill="#767676"/>
                                    <path d="M6.375 10.8752C6.2775 10.8752 6.18 10.8527 6.09 10.8152C5.9925 10.7777 5.9175 10.7252 5.8425 10.6577C5.7075 10.5152 5.625 10.3277 5.625 10.1252C5.625 10.0277 5.6475 9.93023 5.685 9.84023C5.7225 9.75023 5.775 9.66774 5.8425 9.59274C5.9175 9.52524 5.9925 9.47272 6.09 9.43522C6.36 9.32272 6.6975 9.38274 6.9075 9.59274C7.0425 9.73524 7.125 9.93023 7.125 10.1252C7.125 10.1702 7.1175 10.2227 7.11 10.2752C7.1025 10.3202 7.0875 10.3652 7.065 10.4102C7.05 10.4552 7.0275 10.5002 6.9975 10.5452C6.975 10.5827 6.9375 10.6202 6.9075 10.6577C6.765 10.7927 6.57 10.8752 6.375 10.8752Z" fill="#767676"/>
                                    <path d="M9 10.8751C8.9025 10.8751 8.805 10.8527 8.715 10.8152C8.6175 10.7777 8.5425 10.7251 8.4675 10.6576C8.3325 10.5151 8.25 10.3276 8.25 10.1251C8.25 10.0276 8.2725 9.93015 8.31 9.84015C8.3475 9.75015 8.4 9.66766 8.4675 9.59266C8.5425 9.52516 8.6175 9.47264 8.715 9.43514C8.985 9.31514 9.3225 9.38266 9.5325 9.59266C9.6675 9.73516 9.75 9.93015 9.75 10.1251C9.75 10.1701 9.7425 10.2227 9.735 10.2752C9.7275 10.3202 9.7125 10.3652 9.69 10.4102C9.675 10.4552 9.6525 10.5001 9.6225 10.5451C9.6 10.5826 9.5625 10.6201 9.5325 10.6576C9.39 10.7926 9.195 10.8751 9 10.8751Z" fill="#767676"/>
                                    <path d="M11.625 10.8751C11.5275 10.8751 11.43 10.8527 11.34 10.8152C11.2425 10.7777 11.1675 10.7251 11.0925 10.6576C11.0625 10.6201 11.0325 10.5826 11.0025 10.5451C10.9725 10.5001 10.95 10.4552 10.935 10.4102C10.9125 10.3652 10.8975 10.3202 10.89 10.2752C10.8825 10.2227 10.875 10.1701 10.875 10.1251C10.875 9.93015 10.9575 9.73516 11.0925 9.59266C11.1675 9.52516 11.2425 9.47264 11.34 9.43514C11.6175 9.31514 11.9475 9.38266 12.1575 9.59266C12.2925 9.73516 12.375 9.93015 12.375 10.1251C12.375 10.1701 12.3675 10.2227 12.36 10.2752C12.3525 10.3202 12.3375 10.3652 12.315 10.4102C12.3 10.4552 12.2775 10.5001 12.2475 10.5451C12.225 10.5826 12.1875 10.6201 12.1575 10.6576C12.015 10.7926 11.82 10.8751 11.625 10.8751Z" fill="#767676"/>
                                    <path d="M6.375 13.4999C6.2775 13.4999 6.18 13.4774 6.09 13.4399C6 13.4024 5.9175 13.3499 5.8425 13.2824C5.7075 13.1399 5.625 12.9449 5.625 12.7499C5.625 12.6524 5.6475 12.5549 5.685 12.4649C5.7225 12.3674 5.775 12.2849 5.8425 12.2174C6.12 11.9399 6.63 11.9399 6.9075 12.2174C7.0425 12.3599 7.125 12.5549 7.125 12.7499C7.125 12.9449 7.0425 13.1399 6.9075 13.2824C6.765 13.4174 6.57 13.4999 6.375 13.4999Z" fill="#767676"/>
                                    <path d="M9 13.4999C8.805 13.4999 8.61 13.4174 8.4675 13.2824C8.3325 13.1399 8.25 12.9449 8.25 12.7499C8.25 12.6524 8.2725 12.5549 8.31 12.4649C8.3475 12.3674 8.4 12.2849 8.4675 12.2174C8.745 11.9399 9.255 11.9399 9.5325 12.2174C9.6 12.2849 9.6525 12.3674 9.69 12.4649C9.7275 12.5549 9.75 12.6524 9.75 12.7499C9.75 12.9449 9.6675 13.1399 9.5325 13.2824C9.39 13.4174 9.195 13.4999 9 13.4999Z" fill="#767676"/>
                                    <path d="M11.625 13.5002C11.43 13.5002 11.235 13.4177 11.0925 13.2827C11.025 13.2152 10.9725 13.1327 10.935 13.0352C10.8975 12.9452 10.875 12.8477 10.875 12.7502C10.875 12.6527 10.8975 12.5552 10.935 12.4652C10.9725 12.3677 11.025 12.2852 11.0925 12.2177C11.265 12.0452 11.5275 11.9627 11.7675 12.0152C11.82 12.0227 11.865 12.0377 11.91 12.0602C11.955 12.0752 12 12.0977 12.045 12.1277C12.0825 12.1502 12.12 12.1877 12.1575 12.2177C12.2925 12.3602 12.375 12.5552 12.375 12.7502C12.375 12.9452 12.2925 13.1402 12.1575 13.2827C12.015 13.4177 11.82 13.5002 11.625 13.5002Z" fill="#767676"/>
                                    <path d="M15.375 7.37988H2.625C2.3175 7.37988 2.0625 7.12488 2.0625 6.81738C2.0625 6.50988 2.3175 6.25488 2.625 6.25488H15.375C15.6825 6.25488 15.9375 6.50988 15.9375 6.81738C15.9375 7.12488 15.6825 7.37988 15.375 7.37988Z" fill="#767676"/>
                                    <path d="M12 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.75C16.3125 15.4875 14.7375 17.0625 12 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H12C14.145 15.9375 15.1875 14.895 15.1875 12.75V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z" fill="#767676"/>
                                </svg>
                                13 Jun 2023
                            </span>
                        </div>
                    </div>
                    <div className='Booking-card-right'>
                        <div className="Booking-card-order">
                            <h2>ORDER #357216</h2>
                        </div>
                        <div className="Booking-card-btn">
                            <Button>Download Ticket</Button>
                        </div>
                    </div>
                    <span className='Booking-card-sun'>
                        <svg width="74" height="110" viewBox="0 0 94 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51.949 87.9147C51.949 60.9869 28.6871 39.1576 0.00250918 39.1576L0.00251124 62.7282C14.8245 62.7282 26.8378 73.9981 26.8378 87.9147C26.8378 101.817 14.8245 113.101 0.00251564 113.101L0.0025177 136.672C28.6871 136.672 51.949 114.843 51.949 87.9147Z" fill="#FEAE17"/>
                            <path d="M26.8389 87.9124C26.8389 74.0096 14.8255 62.7258 0.00356615 62.7258L0.00356835 87.8845L0.00357056 113.085C14.8256 113.099 26.8389 101.829 26.8389 87.9124Z" fill="#004F6C"/>
                            <path d="M-0.000203282 -1.22331e-05L-0.000201326 22.3725C4.37965 22.3725 8.63436 22.8044 12.7917 23.5288L-0.000203282 -1.22331e-05Z" fill="#FF9C54"/>
                            <path d="M14.3943 26.4486C9.75025 25.4874 4.95326 24.958 0.00332568 24.958L0.00332619 30.7671C6.31588 30.7671 12.406 31.6726 18.1345 33.3442L14.3943 26.4486Z" fill="#004F6C"/>
                            <path d="M65.4877 74.4126L58.1462 70.8882C59.926 76.2654 60.8993 81.9909 60.8993 87.9114C60.8993 93.8458 59.9399 99.5574 58.1462 104.935L65.4877 101.41C66.5027 97.0498 67.0867 92.5503 67.0867 87.9114C67.0867 83.2725 66.5027 78.7589 65.4877 74.4126Z" fill="#004F6C"/>
                            <path d="M68.6046 75.9075C69.3832 79.808 69.8282 83.8061 69.8282 87.9156C69.8282 92.0252 69.3693 96.0233 68.6046 99.9238L93.6602 87.9156L68.6046 75.9075Z" fill="#FF9C54"/>
                            <path d="M36.0831 34.921L28.2411 37.3031C39.1977 42.6943 48.1798 51.1223 53.9223 61.417L56.4668 54.0616C51.2249 46.3719 44.2588 39.8385 36.0831 34.921Z" fill="#004F6C"/>
                            <path d="M57.5615 50.874L66.2378 25.7432L39.472 33.8787C46.5493 38.4618 52.6811 44.2291 57.5615 50.874Z" fill="#FF9C54"/>
                            <path d="M28.2411 138.525L36.0831 140.907C44.2727 135.99 51.2249 129.456 56.4668 121.767L53.9223 114.411C48.1659 124.706 39.1977 133.134 28.2411 138.525Z" fill="#004F6C"/>
                            <path d="M39.4645 141.948L66.2302 150.084L57.5539 124.953C52.6874 131.598 46.5557 137.365 39.4645 141.948Z" fill="#FF9C54"/>
                            <path d="M4.09607e-05 153.47L4.29153e-05 175.829L12.792 152.3C8.64851 153.039 4.3799 153.47 4.09607e-05 153.47Z" fill="#FF9C54"/>
                            <path d="M18.1509 142.494C12.4223 144.166 6.33221 145.071 0.0196643 145.071L0.0196648 150.88C4.95569 150.88 9.76659 150.337 14.4106 149.39L18.1509 142.494Z" fill="#004F6C"/>
                        </svg>
                    </span>
                    <div className="Booking-card-tree">
                        <svg width="63" height="221" viewBox="0 0 63 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.1563 65.4119L51.4865 29.2261H10.8359L31.1563 65.4119Z" fill="#FFC800"/>
                            <path d="M31.1563 51.8065L51.4865 15.6206H10.8359L31.1563 51.8065Z" fill="#007699"/>
                            <path d="M31.1563 36.176L51.4865 0H10.8359L31.1563 36.176Z" fill="#004F6C"/>
                            <path d="M35.0954 81.6279H27.5403V240.173H35.0954V81.6279Z" fill="#0087A7"/>
                            <path d="M31.3195 121.028L0 75.4147L2.94356 73.5371L31.3195 114.864L59.6856 73.5371L62.639 75.4147L31.3195 121.028Z" fill="#0087A7"/>
                            <path d="M31.3195 87.7008L0 42.0777L2.94356 40.21L31.3195 81.5371L59.6856 40.21L62.639 42.0777L31.3195 87.7008Z" fill="#0087A7"/>
                        </svg>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Bookings