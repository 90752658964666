import React from 'react';
import './forgotPass.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../Common/AntdTextField';
import { Button, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgetPasswordApi } from '../../apis/auth/forgetPassApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import ChangePassword from './ChangePassword';
import { useTranslation } from 'react-i18next';
import OtpVerification from '../Otp-Verification/OtpVerification';

let Regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ForgotPassword = () => {
    const {t} = useTranslation();
    const lang = localStorage.getItem("lang");
    let navigate = useNavigate();
    const schema = Yup.object().shape({
        email: Yup.string()
            .matches(Regex , t('emailValidateCorrect'))
            .optional(t('emailValidate'))
            .email(t('emailValidateCorrect')),
    });
    const [passIsStored, setPassIsStored] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState();
    const customApiRequest = useCustomApiRequest();
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            email: '',
        }
    });
    
    const onSubmit = (data) => {
        setUserEmail(data?.email)
        customApiRequest(
            forgetPasswordApi(data?.email , lang),
            (res) => {
                if (res?.data?.status) {
                    setPassIsStored(true)
                    // navigate('/OtpVerification');
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    
                } else if (!res?.data.status) {
                    toast.error(res?.data?.message, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.error(res?.data?.message, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {

            }
        )
    }
    const [form] = Form.useForm();
    return (
        <>
            
            {
                !passIsStored ?
                <div className='forgot-password-page'>
                    <div className="forgot-password-page-content">
                        <div className="forgot-password-page-content-form">
                            <h2>{t('Forgot Password')}</h2>
                            <Form
                                className="forgot-password-form"
                                form={form}
                                layout="vertical"
                                onFinish={handleSubmit(onSubmit)}
                            >
                                <div className="form-body">
                                    <AntdTextField
                                        className="form-text-field"
                                        name="email"
                                        type="email"
                                        placeholder={t('Email us')}
                                        label={t("Email us")}
                                        errorMsg={errors?.email?.message}
                                        validateStatus={errors?.email ? 'error' : ''}
                                        // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                        control={control}
                                    />
                                    <Button
                                        className="forgot-password-btn"
                                        htmlType="submit"
                                        type="primary"
                                        // icon={<LoginOutlined />}
                                        // loading={isSubmitting}
                                        // loading={isLoadingSignin}
                                    >
                                        {t('send')}
                                    </Button>
                                </div>
                            </Form>
                            <div className='forgot-password-page-title'>
                                <span className='forgot-password-page-title-dash'></span>
                                <span> Or</span>
                                <span className='forgot-password-page-title-dash'></span>
                            </div>
                            <div className='forgot-password-btn-group'>
                                <div>
                                    <Link to='/login'>{t('login')}</Link>
                                </div>
                                <div>
                                    <Link to='/register'>{t('register')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <OtpVerification userEmail={userEmail} type={'reset'}/>
            }
        </>
    )
}

export default ForgotPassword