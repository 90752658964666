import axios from 'axios';

const updateProfileApi = async (token, values, lang) => {
  try {
    const Res = await axios.post('/updateProfile', values, {
      headers: {
        lang: lang,
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      }
    });
    return Res;
  } catch (error) {
    console.log(error);
  }
};

export default updateProfileApi;
