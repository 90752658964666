import React, { useEffect, useState } from 'react'
import './OurPartners.css'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
// import 'swiper/modules/pagination/pagination.css'
import partnerImg1 from '../../Images/Logo Client 1.png';
import partnerImg2 from '../../Images/Logo Client 2.png';
import partnerImg3 from '../../Images/Logo Client 4.png';
import partnerImg4 from '../../Images/Logo Client 1.png';
import partnerImg5 from '../../Images/Logo Client 2.png';
import partnerImg6 from '../../Images/Logo Client 4.png';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import partnersApi from '../../apis/data/partnersApi';
import Cookies from 'js-cookie';

const OurPartners = () => {
    const {t} = useTranslation()
    // let partnerList = [
    //     {
    //         id : '1',
    //         img : partnerImg1
    //     },
    //     {
    //         id : '2',
    //         img : partnerImg2
    //     },
    //     {
    //         id : '3',
    //         img : partnerImg3
    //     },
    //     {
    //         id : '4',
    //         img : partnerImg4
    //     },
    //     {
    //         id : '5',
    //         img : partnerImg5
    //     },
    //     {
    //         id : '6',
    //         img : partnerImg6
    //     },
    // ];
    const lang = localStorage.getItem("lang");
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')));
    const [partnerList , setPartnerList] = useState();
    const customApiRequest = useCustomApiRequest();
    useEffect(() => {
        customApiRequest(
            partnersApi(user?.token , lang),
            (res) => {
                if (res?.status && res?.data?.data?.paratiners) {
                    setPartnerList(res?.data?.data?.paratiners)
                }
            }
        )
    } , [])
    // const PartnerCard = () => {
    //     return (
    //         <div className='partner-card'>
    //             <img src={partnerImg} alt="partner-img" />
    //         </div>
    //     )
    // }
    return (
        <div className='Our-Partners'>
            <div className='Our-Partners-title'>
                <span className='dash-line'></span>
                <h3>{t('Our Partners')}</h3>
                <span className='dash-line'></span>
            </div>
            <div className="Our-Partners-content">
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        480: {
                            slidesPerView: 3,
                            spaceBetween: 10
                        },
                        540: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    }}
                    // dir={'ltr'}
                    dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}
                    className="mySwiper"
                >
                    {
                        partnerList?.length && partnerList?.map((partner , index) => (
                            <SwiperSlide>
                                <div className='partner-card' key={index}>
                                    <img src={partner.image} alt="partner-img" />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default OurPartners