import React, { useState } from "react";
import "./otp.css";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Button } from "antd";
import { store } from "react-notifications-component";
import OtpVerificationApi from "../../apis/auth/otp-verification/OtpVerificationApi";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OtpResendCode from "../../apis/auth/otp-verification/OtpResendCode";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useContext } from "react";
import UserContext, { UserProvider } from "../../contexts/UserProvider";

const OtpVerification = ({userEmail , type}) => {
    const lang = localStorage.getItem("lang");
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {email , setEmail} = useContext(UserContext)
    const userToken = Cookies.get('user-token') && JSON.parse(Cookies.get('user-token'))
    // const userOtp = (Cookies.get('user-otp') && Cookies.get('user-otp'))
    const [OTP, setOTP] = useState("");
    const [len, setLen] = useState(4);
    const renderButton = (buttonProps) => {
        return <button className='resend-otp-btn' {...buttonProps}>{t('re-msg')}</button>;
    };
    const renderTime = (remainingTime) => {
        return <span className='remainingtime'>{remainingTime} {t('time-msg')}</span>;
    };
    const handleSubmitOtp = async () => {
        try{
            const res = await OtpVerificationApi(userToken , OTP , email || userEmail , type, lang);
            if(res.status === 200 && res?.data?.data) {
                if (type == 'reset') {
                    setEmail(res?.data?.data?.email)
                    navigate('/ChangePassword')
                } else {
                    Cookies.set('user' , JSON.stringify(res?.data?.data?.user))
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate('/')
                }
            } else {
                toast.error(res?.data?.message, {
                    position: "bottom-left",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch(error) {
            throw error
        }
    };
    
    const handleReSendCode = async () => {
        try {
            const res = await OtpResendCode(userToken);
            if (res.status === 200 && res.data.status) {
                toast.success(res?.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(res?.data?.message, {
                    position: "bottom-left",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    // OtpVerificationApi
    return (
        <div className="otp-verification">
            <div className="otp-verification-content">
                <div className="otp-verification-content-form">
                    <h2>{t('OTP Verification')}</h2>
                    <OTPInput
                        className='otp-verification-content-form-input'
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        // secure
                    />
                    <ResendOTP
                        className="otp-input-btn"
                        renderTime={renderTime}
                        renderButton={renderButton}
                        onResendClick={() => handleReSendCode()}
                    />
                    <div className='otp-btn-group'>
                        {len === OTP.length ? (
                            <Button className="otp-btn" variant="contained" onClick={() => handleSubmitOtp()}>{t('confirm')}</Button>
                        ) : (
                            <Button type='submit' disabled className="otp-btn" variant="contained">{t('confirm')}</Button>
                        )}
                    </div>
                </div>
                <div className="otp-verification-content-img">
                    <svg width="203" height="184" viewBox="0 0 293 264" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M266.961 114.519C259.899 99.4435 244.628 89 226.939 89C207.246 89 190.547 101.946 184.842 119.811C172.103 120.594 161.872 130.841 162.001 144.197C162.012 145.457 292.999 145.06 292.999 144.197C293.076 123.991 286.161 114.337 270.232 114.337C269.127 114.333 268.035 114.396 266.961 114.519Z" fill="#EAF6F6"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M128.089 17.7737C132.996 7.27538 143.606 0 155.889 0C169.567 0 181.17 9.01567 185.132 21.459C193.98 22.0026 201.087 29.1403 200.999 38.4419C200.992 39.3174 110 39.042 110 38.4419C109.948 24.3713 114.753 17.6466 125.819 17.6466C126.587 17.643 127.342 17.6889 128.089 17.7737Z" fill="#EAF6F6"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.0893 86.7737C22.9958 76.2754 33.6055 69 45.8894 69C59.5666 69 71.1696 78.0157 75.132 90.459C83.9799 91.0026 91.0869 98.1403 90.9992 107.442C90.9922 108.317 0.000427708 108.042 0.000427708 107.442C-0.0522174 93.3713 4.75253 86.6466 15.8185 86.6466C16.5871 86.643 17.3417 86.6889 18.0893 86.7737Z" fill="#EAF6F6"/>
                        <path d="M194.969 165.741H170.228V98.0903C170.228 86.6582 160.058 77.3615 147.559 77.3615H141.375C128.872 77.3615 118.703 86.6616 118.703 98.0903V165.744H93.9688V98.0903C93.9688 74.1896 115.232 54.7441 141.375 54.7441H147.559C157.568 54.7441 166.866 57.5961 174.525 62.4548C186.867 70.2873 194.965 83.3402 194.965 98.0903V165.741H194.969Z" fill="#FEAE17"/>
                        <path d="M194.677 168.606H169.878C168.514 168.606 167.41 167.501 167.41 166.136V98.0779C167.41 87.9414 158.324 79.6908 147.154 79.6908H140.956C139.593 79.6908 138.488 78.5851 138.488 77.2205C138.488 75.8559 139.593 74.7503 140.956 74.7503H147.154C161.044 74.7503 172.346 85.2157 172.346 98.0744V163.666H192.209V98.0779C192.209 96.7133 193.314 95.6076 194.677 95.6076C196.041 95.6076 197.145 96.7133 197.145 98.0779V166.14C197.145 167.501 196.041 168.606 194.677 168.606ZM118.23 168.606H93.4368C92.0734 168.606 90.9688 167.501 90.9688 166.136V98.0779C90.9688 72.6719 113.395 52 140.956 52H147.154C156.481 52 165.588 54.3933 173.492 58.9245C174.674 59.6033 175.083 61.1113 174.408 62.294C173.73 63.4801 172.22 63.886 171.042 63.2107C163.886 59.1064 155.625 56.9405 147.158 56.9405H140.96C116.118 56.9405 95.9084 75.3941 95.9084 98.0779V163.669H115.765V98.0779C115.765 96.7133 116.87 95.6076 118.233 95.6076C119.596 95.6076 120.701 96.7133 120.701 98.0779V166.14C120.698 167.501 119.593 168.606 118.23 168.606Z" fill="black"/>
                        <path d="M215.969 244.624C215.969 253.49 207.99 260.744 198.242 260.744H90.6951C80.9437 260.744 72.9688 253.486 72.9688 244.624V161.865C72.9688 152.998 80.9472 145.744 90.6951 145.744H198.242C207.994 145.744 215.969 152.998 215.969 161.865V244.624Z" fill="#EAF6F6"/>
                        <path d="M197.96 263.268H91.0838C80.0089 263.268 71 254.909 71 244.633V196.515C71 195.15 72.1047 194.045 73.4681 194.045C74.8315 194.045 75.9362 195.15 75.9362 196.515V244.633C75.9362 252.187 82.7321 258.331 91.0838 258.331H197.96C206.311 258.331 213.107 252.187 213.107 244.633C213.107 243.268 214.212 242.162 215.575 242.162C216.939 242.162 218.043 243.268 218.043 244.633C218.043 254.909 209.035 263.268 197.96 263.268ZM215.575 217.603C214.212 217.603 213.107 216.498 213.107 215.133V161.644C213.107 154.094 206.311 147.949 197.96 147.949H91.0838C85.9693 147.949 81.2254 150.283 78.3938 154.191C77.5897 155.297 76.048 155.539 74.9468 154.744C73.8421 153.943 73.5974 152.4 74.3945 151.294C78.1491 146.105 84.3857 143.009 91.0803 143.009H197.956C209.031 143.009 218.04 151.368 218.04 161.644V215.136C218.043 216.498 216.939 217.603 215.575 217.603Z" fill="black"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M106.5 195C113.399 195 119 200.377 119 206.999C119 213.621 113.399 219 106.5 219C99.6014 219 94 213.624 94 206.999C94 200.374 99.6014 195 106.5 195Z" fill="#FEAE17"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M144.5 195C151.399 195 157 200.377 157 206.999C157 213.621 151.399 219 144.5 219C137.601 219 132 213.623 132 206.999C132 200.374 137.601 195 144.5 195Z" fill="#FEAE17"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M183.5 195C190.399 195 196 200.377 196 206.999C196 213.621 190.399 219 183.5 219C176.601 219 171 213.623 171 206.999C171 200.374 176.601 195 183.5 195Z" fill="#FEAE17"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default OtpVerification;
