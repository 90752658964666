import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from "./store";
import { FavouritesProvider } from './contexts/FavouritesContext';
import { CommentsProvider } from './contexts/CommentsContext';
import { UserProvider } from './contexts/UserProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store()}>
        <Suspense fallback={(<div>Loading ...</div>)}>
            <UserProvider>
                <FavouritesProvider>
                    <CommentsProvider>
                        <App />
                    </CommentsProvider>
                </FavouritesProvider>
            </UserProvider>
        </Suspense>
    </Provider>, document.getElementById("root")
);
