import axios from 'axios';

export const registerApi = async (values, lang) => {
  try {
    const registerRes = await axios.post('/register', values, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        lang: lang
      }
    });
    return registerRes;
  } catch (error) {
    throw error;
  }
};
