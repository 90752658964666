/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useController } from 'react-hook-form';
import { Form, Input } from 'antd';
const { TextArea } = Input;

const AntdTextAreaField = ({
    disabled,
    setValue,
    control,
    name,
    type,
    label,
    placeholder,
    prefix,
    validateStatus,
    defaultValue,
    errorMsg,
    className,
    onChange
    // value
}) => {
const {
    // field: { ...inputProps }
    field
} = useController({
    name,
    control
});

React.useEffect(() => {
    if (defaultValue) {
        setValue(name, defaultValue);
    }
}, [defaultValue]);

return (
    <Form.Item
        label={label}
        help={errorMsg}
        validateStatus={validateStatus}
        colon={false}
    >
    <TextArea 
        rows={4} 
        // maxLength={6} 
        disabled={disabled ? disabled : false}
        {...field}
        onChange={(e) => {
        field.onChange(e);
            onChange && onChange();
        }}
        className={className}
        placeholder={placeholder}
        prefix={prefix}
        type={type}
        size="large"
    />
    </Form.Item>
);
};

export default AntdTextAreaField;
