import React, { useEffect, useState } from 'react'
import './restaurantDescription.css'
import { Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import Gallery from "react-photo-gallery";
// import 'swiper/modules/pagination/pagination.min.css';
import SlideImg1 from '../../Images/slide1.png'
import SlideImg2 from '../../Images/slide2.png'
import MapImg from '../../Images/map.jpg'
import { Navigation , Pagination } from 'swiper'
import ProductCard from '../Card/ProductCard'
import { useTranslation } from 'react-i18next'
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest'
import getProductIdApi from '../../apis/product_Id/getProductIdApi'
import Cookies from 'js-cookie'
import { ImageViewer } from 'react-image-viewer-dv'
import getResturantsDetialsApi from '../../apis/resturant_id/getResturantsDetialsApi'
import Maps from '../Maps/Maps'
export const photos = [
    {
        src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
        width: 4,
        height: 3
    },
    {
    src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
    width: 1,
    height: 1
    },
    {
    src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    width: 3,
    height: 4
    },
    {
    src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
    width: 3,
    height: 4
    },
    {
    src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    width: 3,
    height: 4
    },
    {
    src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    width: 3,
    height: 4
    },
    {
    src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/XiDA78wAZVw/600x799",
    width: 3,
    height: 4
    },
    {
    src: "https://source.unsplash.com/x8xJpClTvR0/800x599",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
    width: 4,
    height: 1
    },
    {
    src: "https://source.unsplash.com/qGQNmBE7mYw/800x599",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/NuO6iTBkHxE/800x599",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/pF1ug8ysTtY/600x400",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/A-fubu9QJxE/800x533",
    width: 4,
    height: 3
    },
    {
    src: "https://source.unsplash.com/5P91SF0zNsI/740x494",
    width: 4,
    height: 3
    }
];
const RestaurantDescription = () => {
    const {t} = useTranslation();
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')));
    const customApiRequest = useCustomApiRequest();
    const lang = localStorage.getItem("lang");
    const [productData , setProductData] = useState();
    const [productImages , setProductImages] = useState();
    let url = window.location.href;
    const resturant_id = url.includes('resturant_id') && url?.split('?')[1].split('=')[1]
    useEffect(() => {
        customApiRequest(
            getResturantsDetialsApi(user?.token , resturant_id , lang),
            (res) => {
                if (res?.status && res?.data?.data) {
                    setProductData(res?.data?.data?.resturants)
                    setProductImages(res?.data?.data?.resturants?.cover)
                    res?.data?.data?.resturants?.map((products) => {
                        setProductImages(products?.cover)
                    })
                }
            }
        )
    } , [])
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='restaurant-description'>
            <div className="restaurant-description-content">
                <div className="restaurant-description-header">
                    <div className='rest-logo'>
                        <img src={productData?.logo} alt="" />
                        <h2>{productData?.name}</h2>
                    </div>
                    <div className='rest-call'>
                        <a href={`tel:+${productData?.phone}`}>{t('call')}</a>
                    </div>
                </div>
                <div className="restaurant-description-slider">
                    {/* {productImages?.length > 0 && <Gallery photos={photos} onClick={(e) => console.log(e)}/>} */}
                    {productImages?.length > 0 && productImages?.map((img) => (
                        <ImageViewer>
                            <img src={img?.image} alt="Your image" />
                        </ImageViewer>
                    ))}
                </div>
                <div className='restaurant-description-footer'>
                    <Grid container spacing={5}>
                        <Grid item md={8} xs={12}>
                            <div className='restaurant-description-overview'>
                                <h3>{t('Description')}</h3>
                                <p>{productData?.desc}</p>
                            </div>
                            <div className="restaurant-description-maps">
                                <h3>{t('location')}</h3>
                                <div className='map-img'>
                                    {productData?.lat && productData?.long && <Maps latitude={productData?.lat} longitude={productData?.long} />}
                                </div>
                                {/* <div className='map-img'>
                                    <img src={MapImg} alt="google map img" />
                                    <a href={productData?.location}>
                                        <svg width="38" height="42" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.0813 16.7473C11.6496 16.7473 9.66309 14.7671 9.66309 12.3177C9.66309 9.86816 11.6496 7.89941 14.0813 7.89941C16.513 7.89941 18.4994 9.87961 18.4994 12.3291C18.4994 14.7786 16.513 16.7473 14.0813 16.7473ZM14.0813 9.61635C12.5971 9.61635 11.3756 10.8296 11.3756 12.3291C11.3756 13.8286 12.5857 15.0419 14.0813 15.0419C15.5768 15.0419 16.787 13.8286 16.787 12.3291C16.787 10.8296 15.5654 9.61635 14.0813 9.61635Z" fill="#FEAE17"/>
                                            <path d="M14.0798 26.5808C12.3902 26.5808 10.6891 25.9398 9.36482 24.6693C5.99696 21.4185 2.27518 16.2334 3.67941 10.0639C4.94664 4.46668 9.82148 1.95996 14.0798 1.95996C14.0798 1.95996 14.0798 1.95996 14.0913 1.95996C18.3496 1.95996 23.2244 4.46668 24.4917 10.0753C25.8845 16.2448 22.1627 21.4185 18.7948 24.6693C17.4705 25.9398 15.7695 26.5808 14.0798 26.5808ZM14.0798 3.67689C10.7576 3.67689 6.48787 5.45106 5.35763 10.4416C4.12465 15.8328 7.50393 20.4799 10.5636 23.4216C12.5386 25.3331 15.6325 25.3331 17.6075 23.4216C20.6557 20.4799 24.035 15.8328 22.8249 10.4416C21.6832 5.45106 17.402 3.67689 14.0798 3.67689Z" fill="#FEAE17"/>
                                        </svg>
                                    </a>
                                </div> */}
                            </div>
                        </Grid>
                        {/* <Grid item md={4} xs={12}>
                            <div className="restaurant-description-price">
                                <div>
                                    <h3>{t('From')}</h3>
                                    <h2>{productData?.price} {t('Qar')}</h2>
                                    <h3>{t('Per Person')}</h3>
                                </div>
                                <Button className='booking-btn'>
                                    <Link to={`/booking?ads-id=${resturant_id}`}>{t('Booking now')}</Link>
                                </Button>
                            </div>
                        </Grid> */}
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default RestaurantDescription