import axios from 'axios';

export const contactApi = async (token, values, lang) => {
    try {
        const Res = await axios.post('/contact', values, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
            lang: lang
        }
        });
        return Res;
    } catch (error) {
        throw error;
    }
};