import { Button, Grid } from '@mui/material'
import React from 'react'
import PassImg from '../../Images/pass-bg.png'
import './PassSection.css'
import { useTranslation } from 'react-i18next'
const PassSection = () => {
    const {t} = useTranslation()
    return (
        <div className='Pass-section'>
            <Grid container>
                <Grid item md={6} xs={12} order={1}>
                    <div className="Pass-section-content">
                        <h2>{t('What is YAS Pass ?')}</h2>
                        <p>{t("yas text 1")}</p>
                        <p>{t("yas text 2")}</p>
                        <p>{t("yas text 3")}</p>
                        {/* <Button variant="contained">{t('GET IT')}</Button> */}
                    </div>
                </Grid>
                <Grid item md={6} xs={12} >
                    <div className="Pass-section-img">
                        <img src={PassImg} alt="pass" />
                    </div>
                </Grid>
            </Grid>
            <div className="pass-sun">
                <svg width="177" height="98" viewBox="0 0 177 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M88.2994 54.1618C115.23 54.42 137.267 30.7492 137.521 1.30238L113.948 1.07639C113.817 16.2923 102.44 28.5168 88.5217 28.3833C74.6178 28.25 63.4394 15.8093 63.5707 0.593426L39.998 0.367437C39.7441 29.8143 61.3692 53.9036 88.2994 54.1618Z" fill="#27BECE"/>
                    <path d="M88.5273 28.379C102.431 28.5123 113.822 16.288 113.954 1.0721L88.7928 0.830881L63.5902 0.589265C63.445 15.805 74.6094 28.2456 88.5273 28.379Z" fill="#004F6C"/>
                    <path d="M176.676 1.6473L154.302 1.43279C154.263 5.92908 153.793 10.2928 153.032 14.5537L176.676 1.6473Z" fill="#E30000"/>
                    <path d="M150.101 16.1783C151.104 11.4185 151.676 6.49739 151.72 1.41419L145.908 1.35848C145.852 7.84099 144.892 14.0863 143.169 19.9531L150.101 16.1783Z" fill="#004F6C"/>
                    <path d="M101.674 68.2064L105.263 60.7071C99.8699 62.4818 94.1357 63.4256 88.2151 63.3689C82.2805 63.312 76.5773 62.2728 71.2159 60.3808L74.6754 67.9477C79.0268 69.031 83.5213 69.6734 88.1603 69.7178C92.7993 69.7623 97.3181 69.2063 101.674 68.2064Z" fill="#004F6C"/>
                    <path d="M100.148 71.3988C96.2402 72.1609 92.2374 72.5794 88.127 72.54C84.0166 72.5006 80.0218 71.9911 76.1272 71.1685L87.9159 97.0107L100.148 71.3988Z" fill="#E30000"/>
                    <path d="M141.43 38.3797L139.117 30.3046C133.628 41.5032 125.118 50.6453 114.771 56.443L122.105 59.1262C129.843 53.8175 136.439 46.7274 141.43 38.3797Z" fill="#004F6C"/>
                    <path d="M125.279 60.2806L150.338 69.4305L142.438 41.8692C137.791 49.0922 131.969 55.3331 125.279 60.2806Z" fill="#E30000"/>
                    <path d="M37.8943 29.353L35.4422 37.3824C40.2882 45.8387 46.7615 53.0399 54.4064 58.4961L61.7859 55.9539C51.5399 49.9445 43.1896 40.655 37.8943 29.353Z" fill="#004F6C"/>
                    <path d="M34.3704 40.8497L25.9961 68.2549L51.2091 59.587C44.6059 54.5264 38.8917 48.1749 34.3704 40.8497Z" fill="#E30000"/>
                    <path d="M23.2121 0.214202L0.851546 -0.000169253L24.2691 13.3574C23.5674 9.09672 23.1734 4.71049 23.2121 0.214202Z" fill="#E30000"/>
                    <path d="M34.0278 18.9525C32.4061 13.0537 31.5541 6.79091 31.6101 0.308398L25.7983 0.252684C25.7546 5.32161 26.2555 10.2672 27.1621 15.0454L34.0278 18.9525Z" fill="#004F6C"/>
                </svg>
            </div>
            <div className="pass-zigzag">
                <svg width="28" height="500" viewBox="0 0 28 597" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28 18.6761L11.3982 37.3522L28 56.0283L11.5469 74.5415L28 93.0546L11.3982 111.747L28 130.423L25.1256 133.65L25.1422 133.666L11.4147 149.116L25.1422 164.565L25.1256 164.597L28 167.808L11.3982 186.5L28 205.177L11.4312 223.82L28 242.463L11.3982 261.14L28 279.816L11.3982 298.492L28 317.168L11.3982 335.844L28 354.536L11.5469 373.05L28 391.563L11.3982 410.239L28 428.915L25.1256 432.142L25.1422 432.158L11.4147 447.607L25.1422 463.073L25.1257 463.089L28 466.316L11.3982 484.992L28 503.668L11.4313 522.312L28 540.955L11.3982 559.631L28 578.324L11.3982 597L0 597L16.6018 578.324L-1.59036e-06 559.631L16.5687 540.988L-3.17725e-06 522.344L0.0330422 522.312L-3.18002e-06 522.279L16.5687 503.636L-4.76691e-06 484.992L16.6018 466.316L-6.35658e-06 447.64L0.0165163 447.607L-6.35866e-06 447.591L16.6018 428.915L-7.94832e-06 410.239L16.4531 391.726L-9.52411e-06 373.213L0.14867 373.05L-9.53868e-06 372.87L16.4531 354.357L-1.11145e-05 335.844L16.6018 317.168L-1.27034e-05 298.508L0.01651 298.492L-1.27041e-05 298.492L16.6018 279.816L-1.42938e-05 261.14L16.5687 242.496L-1.58807e-05 223.853L0.0330295 223.82L-1.58835e-05 223.787L16.5687 205.144L-1.74704e-05 186.5L16.6018 167.808L-1.90607e-05 149.132L0.0165036 149.116L-1.90621e-05 149.099L16.6018 130.423L-2.06518e-05 111.747L16.4531 93.2339L-2.22276e-05 74.7208L0.148657 74.5415L-2.22421e-05 74.3785L16.4531 55.8654L-2.38179e-05 37.3522L16.6017 18.6761L-2.54076e-05 0L11.3982 -5.11726e-07L28 18.6761Z" fill="#69D1FF"/>
                </svg>
            </div>
            <div className="pass-tree">
                <svg width="85" height="327" viewBox="0 0 135 527" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M66.8629 143.961L110.217 64.7041H23.5293L66.8629 143.961Z" fill="#69D1FF"/>
                    <path d="M66.8629 114.162L110.217 34.9053H23.5293L66.8629 114.162Z" fill="#007699"/>
                    <path d="M66.8629 79.9269L110.217 0.691406H23.5293L66.8629 79.9269Z" fill="#004F6C"/>
                    <path d="M75.2658 179.479H59.1543V526.736H75.2658V179.479Z" fill="#69D1FF"/>
                    <path d="M67.2122 265.777L0.422852 165.871L6.70005 161.759L67.2122 252.277L127.704 161.759L134.002 165.871L67.2122 265.777Z" fill="#69D1FF"/>
                    <path d="M67.2122 192.783L0.422852 92.8556L6.70005 88.7646L67.2122 179.282L127.704 88.7646L134.002 92.8556L67.2122 192.783Z" fill="#69D1FF"/>
                </svg>
            </div>
            {/* <div className="pass-shapes-1">
                <svg width="135" height="150" viewBox="0 0 235 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M73.5109 212.357L123.448 248.547L125.256 179.632L73.5109 212.357Z" fill="#004F6C"/>
                    <path d="M75.9851 129.843L125.931 166.021L127.739 97.1065L75.9851 129.843Z" fill="#004F6C"/>
                    <path d="M78.4685 47.3174L128.403 83.4876L130.222 14.5811L78.4685 47.3174Z" fill="#004F6C"/>
                </svg>
            </div>
            <div className="pass-shapes-2">
                <svg width="105" height="90" viewBox="0 0 125 133" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M89.1188 23.074L57.4672 3.83063L59.6395 45.2944L89.1188 23.074Z" fill="#F04F4F"/>
                    <path d="M85.1345 103.984L53.4746 84.7523L55.6469 126.216L85.1345 103.984Z" fill="#F04F4F"/>
                </svg>
            </div> */}
        </div>
    )
}

export default PassSection