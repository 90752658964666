import axios from 'axios';

export const addOrderApi = async (token, values, lang) => {
    try {
        const orderRes = await axios.post('/order', values, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: token,
            lang: lang
        }
        });
        return orderRes;
    } catch (error) {
        throw error;
    }
};