import React from 'react'
import Content from '../../Components/Content/Content'
import { useEffect } from 'react';

const Home = () => {
    return (
        <div>
            <Content />
        </div>
    )
}

export default Home