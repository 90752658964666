import axios from 'axios';

export const changePassApi = async (values , email , lang) => {
  try {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', values.password);
    formData.append('confirm_password', values.confirm_password);
    const res = await axios.post('/changePassword', formData, {
      headers: {
        lang: lang
      }
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
