import React from 'react'
import { useNavigate } from 'react-router-dom';

const cont = {
    backgroundColor: "#eee",
    overflow: "hidden",
    position: "relative",
    cursor: 'pointer'
};
const ImageWithCaption = ({ index, photo, margin, direction, top, left }) => {
    const navigate = useNavigate();
    return (
        <div style={{ margin, height: photo.height, width: photo.width, ...cont }} onClick={(e) => navigate(`TourDescription?ads_id=${photo?.id}`)}>
            <img src={photo.src} alt="" width={'100%'} height={'100%'}/>
            <div class="overlay-text" style={{position: 'absolute', bottom: '10px', backgroundColor: '#fff', padding:'10px 20px'}}>{photo.caption}</div>
        </div>
    )
}

export default ImageWithCaption;