import React, { useEffect } from 'react'
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest'
import { toast } from 'react-toastify'
import getServicesApi from '../../apis/Services/getServicesApi'
import { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import ProductCard from '../Card/ProductCard'
import './Services.css'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'
import Gallery from 'react-photo-gallery'

import { useNavigate } from 'react-router-dom'
import ImageWithCaption from './ImageWithCaption'
const Services = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    const [services , setServices] = useState();
    useEffect(() => {
        customApiRequest(
            getServicesApi(lang),
            (res) => {
                if (res?.status && res?.data?.data?.ads) {
                    setServices(res?.data?.data?.ads)
                }
            }
        )
    } , [lang])

    const imageRenderer = ({ index, left, top, key, photo }) => (
        <ImageWithCaption
            key={key}
            margin={"2px"}
            index={index}
            photo={photo}
            left={left}
            top={top}
        />
    );
    return (
        <>
            {services?.length && 
            <div className='services-container'>    
                <div className='services-content'>
                    <div className='services-header'>
                        <h2 data-aos="fade-left">{t('services')}</h2>
                    </div>
                    {services?.length && <div className='services-content-carousel yas-swiper-ltr' data-aos="fade-up">
                        <Gallery photos={services} renderImage={imageRenderer} />
                    </div>}
                </div>
            </div>}
        </>
    )
}

export default Services