import axios from 'axios';

const getPostDetailsApi = async (lang , post_id) => {
    try {
        const res = await axios.get(`/postDetials?post_id=${post_id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            // Authorization: 'Bearer ' + token,
            lang: lang
        }
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default getPostDetailsApi;