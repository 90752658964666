import { Breadcrumbs, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Restaurants.css'
import ProductCard from '../../Components/Card/ProductCard'
import getTourApi from '../../apis/categories/getTourApi'
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'
import getResturantsApi from '../../apis/yassApi/getResturantsApi'
import Cookies from 'js-cookie'

const Restaurants = () => {
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const {t} = useTranslation()
    const lang = localStorage.getItem("lang");
    const [getAllRestaurants , setGetAllRestaurants] = useState();
    console.log("🚀 ~ file: Restaurants.jsx:18 ~ Restaurants ~ getAllRestaurants:", getAllRestaurants)
    const customApiRequest = useCustomApiRequest();
    let url = window.location.href;
    const category_id = url.includes('category_id') && url?.split('?')[1].split('=')[1]

    useEffect(() => {
        customApiRequest(
            getResturantsApi(user?.token , lang),
            (res) => {
                if (res?.status && res?.data?.data?.resturants) {
                    setGetAllRestaurants(res?.data?.data?.resturants)
                }
            }
        )
    } , [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='restaurants'>
            <div className="blog-container-header">
                <h2>{t('YAS PASS OFFERS')}</h2>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="blue" href="/">
                        {t('Home')}
                    </Link>
                    <Typography color="text.primary">{t('Restaurants')}</Typography>
                </Breadcrumbs>
            </div>
            <div className="restaurants-content">
                <div className="restaurants-content-all">
                    {/* <div className='restaurants-content-all-header'>
                        <div>
                            <h3>{t("restaurants-available")}  ({getAllRestaurants?.length})</h3>
                        </div>
                    </div> */}
                    <Grid container spacing={3}>
                        {
                            getAllRestaurants?.length ? getAllRestaurants?.map((tour) => (
                                <Grid item lg={3} md={6} xs={6}>
                                    <div className='restaurant-card'>
                                        <Card>
                                            <CardActionArea>
                                                <div className='img-groups'>
                                                    <CardMedia
                                                        className='card-img'
                                                        component="img"
                                                        height="180"
                                                        image={tour?.cover}
                                                        alt="card img"
                                                    />
                                                    <div className='restaurant-logo'>
                                                        <img src={tour?.logo} alt="logo" />
                                                    </div>
                                                </div>
                                                <CardContent className='restaurant-card-all'>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        <h4>{tour?.name}</h4>
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <div className='card-text'>{tour?.desc}</div>
                                                    </Typography>
                                                </CardContent>
                                                <CardActions style={{padding: '0px'}}>
                                                    <Link className='restaurant-view' to={`/restaurantDescription?resturant_id=${tour?.id}`}>{t('restaurant-details')}</Link>
                                                </CardActions>
                                            </CardActionArea>
                                        </Card>
                                        {tour?.offers > 0 && 
                                        <span className='card-cancel'>
                                            <b>{t('discount')}</b>
                                            <b>{tour?.offers} %</b>
                                        </span>}
                                    </div>
                                </Grid>
                            )) 
                            : 
                            <div className='loading-data'>
                                <Spin />
                            </div>
                        }
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Restaurants