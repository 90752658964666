import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './customizedPackages.css'
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import Cookies from 'js-cookie';
import customizedTripApi from '../../apis/data/customizedTripApi';
const CustomizedPackages = () => {
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const { t } = useTranslation();
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    const [Customized , setCustomized] = useState()
    useEffect(() => {
        customApiRequest(
            customizedTripApi(user?.token),
            (res) => {
                if (res?.data?.status) {
                    setCustomized(res?.data?.data?.customize_your_trip);
                } 
            },  
            (error) => {

            }
        )
    } , [])
    return (
        <div className='customized-packages'>
            {/* <div className='customized-packages-header'>
                <img src="https://images.squarespace-cdn.com/content/v1/54af99b7e4b02e23210f1359/1552451709850-VZW0YE4YZ1ZED5IHVA7T/_KFJ4447.jpg" alt="" />
                <h2>Customized Packages</h2>
            </div> */}
            <div className='customized-packages-custom'>
                <h2>Tour / Customize Your Trip</h2>
                <p>{Customized?.value}</p>
                <Link to='/contact'>{t('contact')}</Link>
                {/* <p>It can be a little overwhelming planning a multi day itinerary in Oman or Saudi, deciding where to stay, what to do and where to eat! Husaak’s adventure experts have local knowledge and are deeply passionate about sharing the hidden jewels of Arabia with visitors. We work with private clients to curated their perfect trip. </p>
                <Link to=''>Enquire Today</Link>
                <q><em>If you are an independent traveller, interested in planning your own itinerary, visit our range of day trips and excursions. </em></q>
                <q><em>With easy online booking, you can pic and mix day trips to build your own itinerary.</em></q> */}
            </div>
        </div>
    )
}

export default CustomizedPackages