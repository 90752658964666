import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Tours from './Pages/Tours/Tours';
import Destination from './Pages/Destination/Destination';
import Blog from './Pages/Blog/Blog';
import Page from './Pages/Page/Page';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import ForgotPassword from './Pages/Forgot-password/ForgotPassword';
import NotFound from './Pages/NotFound/NotFound';
import OtpVerification from './Pages/Otp-Verification/OtpVerification';
import SuccessfulAccount from './Components/Successful/SuccessfulAccount';
import FAQ from './Pages/FAQ/FAQ';
import Profile from './Components/Profile/Profile';
import Bookings from './Components/Bookings/Bookings';
import Notifications from './Components/Notifications/Notifications';
import YassPass from './Components/Yass-Pass/YassPass';
import BlogDetails from './Components/Blog-Details/BlogDetails';
import TourDescription from './Components/Tour-Description/TourDescription';
import Booking from './Components/Booking/Booking';
import SuccessfulPayment from './Components/Successful-payment/SuccessfulPayment';
import Favourites from './Pages/Favourites/Favourites';
import Restaurants from './Pages/Restaurants/Restaurants';
import RestaurantDescription from './Components/Restaurant-Description/RestaurantDescription';
import Services from './Pages/Services/Services';
import CustomizedPackages from './Components/customized-packages/CustomizedPackages';
import Terms from './Pages/Terms/Terms';
import Privacy from './Pages/Privacy/Privacy';
import ChangePassword from './Pages/Forgot-password/ChangePassword';

const MyRoutes = () => {
    return (
        <div className='layout'>
            <Routes>
                <Route path="/" element={ <Home/> } />
                <Route path="/restaurants" element={ <Restaurants/> } />
                <Route path="/restaurantDescription" element={ <RestaurantDescription/> } />
                <Route path="/tours" element={ <Tours/> } />
                <Route path="/getServicesDetials" element={ <Services/> } />
                <Route path="/tourDescription" element={ <TourDescription/> } />
                <Route path="/booking" element={ <Booking/> } />
                <Route path="/destination" element={ <Destination/> } />
                <Route path="/blog" element={ <Blog/> } />
                <Route path="/page" element={ <Page/> } />
                <Route path="/about" element={ <About/> } />
                <Route path="/terms" element={ <Terms/> } />
                <Route path="/privacy" element={ <Privacy/> } />
                <Route path="/login" element={ <Login/> } />
                <Route path="/contact" element={ <Contact/> } />
                {/* <Route path="/services" element={ <Services/> } /> */}
                <Route path="/register" element={ <Register/> } />
                <Route path="/forgotPassword" element={ <ForgotPassword/> } />
                <Route path="/ChangePassword" element={ <ChangePassword/> } />
                <Route path="/otpverification" element={ <OtpVerification/> } />
                <Route path="/SuccessfulAccount" element={ <SuccessfulAccount/> } />
                <Route path="/SuccessfulPayment" element={ <SuccessfulPayment/> } />
                <Route path="/FAQ" element={ <FAQ/> } />
                <Route path="/bookings" element={ <Bookings/> } />
                <Route path="/Profile" element={ <Profile/> } />
                <Route path="/blogDetails" element={ <BlogDetails/> } />
                <Route path="/notifications" element={ <Notifications/> } />
                <Route path="/YassPass" element={ <YassPass/> } />
                <Route path="/favourites" element={ <Favourites/> } />
                <Route path="/customized-packages" element={ <CustomizedPackages/> } />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    )
}

export default MyRoutes