import axios from 'axios';

const getProfileDataApi = async (token, lang) => {
  try {
    const res = await axios.get('/getProfileData', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: token,
        lang: lang
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getProfileDataApi;
