import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getCommentsApi from '../../apis/comments/getComments';
import { Swiper, SwiperSlide } from 'swiper/react';
import Img from '../../Images/dummy.png'
import './comments.css'
import { Button, Modal, Rate, Spin } from 'antd';
import AddCommentForm from './AddCommentForm';
import { useContext } from 'react';
import CommentsContext from '../../contexts/CommentsContext';
import ShowMore from 'react-show-more';
import ShowMoreText from "react-show-more-text"; 
const Comments = () => {
    const {fetchCount , comments, setComments , open , setOpen} = useContext(CommentsContext);
    const {t} = useTranslation();
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    useEffect(() => {
        customApiRequest(
            getCommentsApi(user?.token , lang),
            (res) => {
                if (res?.data?.status) {
                    setComments(res?.data?.data?.commenets);
                } 
            },  
            (error) => {

            }
        )
    } , [fetchCount])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className='comments'>
            <div className='Our-Partners-title'>
                <span className='dash-line'></span>
                <h3>{t('Comments')}</h3>
                <span className='dash-line'></span>
            </div>
            <div className="">
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        540: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                    dir={'ltr'}
                    // dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}
                    className="mySwiper"
                >
                    {
                        comments?.length ? 
                        comments?.map((comment , index) => (
                            <SwiperSlide>
                                <div className='comment-card' key={comment?.id}>
                                    <div className='comment-header'>
                                        <img src={Img} alt=''/>
                                        <div>
                                            <h4>{comment?.name}</h4>
                                            <span>{comment?.date}</span>
                                        </div>
                                    </div>
                                    <div className='comment-rate'>
                                        <Rate disabled defaultValue={comment?.rate} />
                                    </div>
                                    <div className='comment-footer'>
                                        <ShowMoreText
                                            lines={2}
                                            more={t('see_more')}
                                            less={t('see_less')}
                                            anchorClass="show-more-less-clickable"
                                            truncatedEndingComponent={"... "}
                                        >
                                            <p>{comment?.commenet}</p>
                                        </ShowMoreText>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                        :
                        <div className='comments-spin'><Spin /></div>
                    }
                </Swiper>
            </div>
            <div className='add-comments'>
                <Button onClick={() => setOpen(true)}>{t('add-comment')}</Button>
            </div>
            {open &&
            <Modal
                title={<p style={{marginBottom: '10px'}}></p>}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                onOk={() => setOpen(false)} 
                onCancel={() => setOpen(false)}
                closeAfterTransition
                // slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}
            >
                <AddCommentForm />
            </Modal>
            }
        </div>
    )
}

export default Comments