import axios from 'axios';

const LogoutApi = async (token, lang) => {
  try {
    const signOutRes = await axios.post('/logout', {} , {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
        lang: lang
      }
    });
    return signOutRes;
  } catch (error) {
    throw error;
  }
};

export default LogoutApi;
