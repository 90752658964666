import React from 'react';
import './forgotPass.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../Common/AntdTextField';
import { Button, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import eyeOffImg from '../../Images/eye-off.png';
import eyeOnImg from '../../Images/eye-on.png';
import { toast } from 'react-toastify';
import { forgetPasswordApi } from '../../apis/auth/forgetPassApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { changePassApi } from '../../apis/auth/changePassApi';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import UserContext, { UserProvider } from '../../contexts/UserProvider';

const schema = Yup.object().shape({
    password: Yup.string()
    .required('من فضلك ادخل الرقم السرى')
    .min(8, 'كلمه السر يجب ألا تقل هن 8 أرقام'),
    confirm_password: Yup.string()
    .required('من فضلك أعد إدخال الرقم السرى')
    .oneOf([Yup.ref('password')], 'كلمه السر غير متطابقه'),
});

const ChangePassword = (props) => {
    const {t} = useTranslation();
    const lang = localStorage.getItem("lang");
    const navigate = useNavigate()
    const {email} = useContext(UserContext)
    const schema = Yup.object().shape({
        password: Yup.string()
            .required(t('passwordValidate'))
            .min(8, t('passwordValidateMin')),
        confirm_password: Yup.string()
            .required(t('passwordConfirmValidate'))
            .oneOf([Yup.ref('password')], t('passwordValidateNonTypical')),
    });
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [passwordVisible2, setPasswordVisible2] = React.useState(false);

    const customApiRequest = useCustomApiRequest();
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            password: '',
            confirm_password: '',
        }
    });
    const onSubmit = (data) => {
        customApiRequest(
            changePassApi(data , email , lang),
            (res) => {
                if (res?.data?.status) {
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate('/login')
                } else if (!res?.data.status) {
                    toast.error(res?.data?.message, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else{
                    toast.error(res?.data?.message, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {

            }
        )
    }
    const [form] = Form.useForm();
    return (
        <>
            <div className='forgot-password-page'>
                <div className="forgot-password-page-content">
                    <div className="forgot-password-page-content-form">
                        <h2>{t('Change Password')}</h2>
                        <Form
                            className="forgot-password-form"
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit(onSubmit)}
                        >
                            <div className="form-body">
                                <div className="form-text-field-password">
                                    <AntdTextField
                                        className="form-text-field"
                                        name="password"
                                        label={t('password')}
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder={t('password')}
                                        errorMsg={errors?.password?.message}
                                        // prefix={<img style={{ height: 22 }} src={lockImg} alt="lock" />}
                                        validateStatus={errors?.password ? 'error' : ''}
                                        control={control}
                                    />
                                    {watch('password') && (
                                        <div
                                            style={document.documentElement.dir == 'ltr' ? {right:'12px'} : {left:'12px'}}
                                            className="eye-icon-btn"
                                            onClick={() => {
                                                setPasswordVisible((prevState) => !prevState);
                                            }}
                                        >
                                            {passwordVisible ? (
                                                <img src={eyeOnImg} alt="eye on" />
                                            ) : (
                                                <img src={eyeOffImg} alt="eye off" />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="form-text-field-password">
                                    <AntdTextField
                                        className="form-text-field"
                                        name="confirm_password"
                                        label={t('confirm password')}
                                        type={passwordVisible2 ? 'text' : 'password'}
                                        placeholder={t('confirm password')}
                                        errorMsg={errors?.confirm_password?.message}
                                        // prefix={<img style={{ height: 22 }} src={lockImg} alt="lock" />}
                                        validateStatus={errors?.confirm_password ? 'error' : ''}
                                        control={control}
                                    />
                                    {watch('password') && (
                                        <div
                                            style={document.documentElement.dir == 'ltr' ? {right:'12px'} : {left:'12px'}}
                                            className="eye-icon-btn"
                                            onClick={() => {
                                                setPasswordVisible2((prevState) => !prevState);
                                            }}
                                        >
                                            {passwordVisible2 ? (
                                                <img src={eyeOnImg} alt="eye on" />
                                            ) : (
                                                <img src={eyeOffImg} alt="eye off" />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <Button
                                    className="forgot-password-btn"
                                    htmlType="submit"
                                    type="primary"
                                    // icon={<LoginOutlined />}
                                    // loading={isSubmitting}
                                    // loading={isLoadingSignin}
                                >
                                    {t('Change')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword