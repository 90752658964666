import { Breadcrumbs, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './tours.css'
import ProductCard from '../../Components/Card/ProductCard'
import getTourApi from '../../apis/categories/getTourApi'
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'

const Tours = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem("lang");
    const [getAllTours , setGetAllTours] = useState();
    const [getTourName , setTourName] = useState();
    const customApiRequest = useCustomApiRequest();
    let url = window.location.href;
    const category_id = url.includes('category_id') && url?.split('?')[1].split('=')[1]
    const [isLoading , setIsLoading] = useState(false);
    useEffect(() => {
        customApiRequest(
            getTourApi(category_id , lang),
            (res) => {
                setIsLoading(true);
                if (res?.status && res?.data?.data?.ads) {
                    setIsLoading(false);
                    setTourName(res?.data?.data?.categoryName);
                    setGetAllTours(res?.data?.data?.ads);
                } else {
                    setIsLoading(false);
                }
            }
        )
    } , [category_id])
    console.log(getAllTours);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='tours'>
            <div className="blog-container-header">
                {/* <h2>in Land Adventures</h2> */}
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="blue" href="/">
                        {t('Home')}
                    </Link>
                    <Typography color="text.primary">{getTourName}</Typography>
                </Breadcrumbs>
            </div>
            <div className="tours-content">
                <div className="tours-content-all">
                    <div className='tours-content-all-header'>
                        <div>
                            <h3>{t("tours-available")}  ({getAllTours?.length})</h3>
                            {/* <Button>Clear filter</Button> */}
                        </div>
                        {/* <div>
                            <h5>Sort by</h5>
                        </div> */}
                    </div>
                    {!isLoading ?
                    <Grid container spacing={3}>
                        {
                            getAllTours?.length && getAllTours?.map((tour) => (
                                <Grid item lg={3} md={6} xs={6}><ProductCard product={{...tour}} /></Grid>
                            )) 
                        }
                    </Grid>
                    :
                    <div className='loading-data'>
                    {
                        getAllTours?.length > 0 ?
                        <Spin />
                        :
                        <div>{t('no-products')}</div>
                    }
                    </div>
                    }
                </div>
                <span className="tours-sun">
                    <svg width="74" height="146" viewBox="0 0 94 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M51.9492 87.9147C51.9492 60.9869 28.6873 39.1576 0.00275332 39.1576L0.00275538 62.7282C14.8247 62.7282 26.8381 73.9981 26.8381 87.9147C26.8381 101.817 14.8247 113.101 0.00275978 113.101L0.00276184 136.672C28.6873 136.672 51.9492 114.843 51.9492 87.9147Z" fill="#FEAE17"/>
                        <path d="M26.8389 87.9124C26.8389 74.0096 14.8255 62.7258 0.00356615 62.7258L0.00356835 87.8845L0.00357056 113.085C14.8256 113.099 26.8389 101.829 26.8389 87.9124Z" fill="#004F6C"/>
                        <path d="M4.08584e-05 -1.22331e-05L4.28143e-05 22.3725C4.3799 22.3725 8.63461 22.8044 12.792 23.5288L4.08584e-05 -1.22331e-05Z" fill="#FF9C54"/>
                        <path d="M14.3945 26.4486C9.75049 25.4874 4.9535 24.958 0.00356982 24.958L0.00357033 30.7671C6.31612 30.7671 12.4062 31.6726 18.1348 33.3442L14.3945 26.4486Z" fill="#004F6C"/>
                        <path d="M65.4879 74.4126L58.1464 70.8882C59.9262 76.2654 60.8995 81.9909 60.8995 87.9114C60.8995 93.8458 59.9401 99.5574 58.1465 104.935L65.4879 101.41C66.5029 97.0498 67.0869 92.5503 67.0869 87.9114C67.0869 83.2725 66.5029 78.7589 65.4879 74.4126Z" fill="#004F6C"/>
                        <path d="M68.6046 75.9075C69.3832 79.808 69.8282 83.8061 69.8282 87.9156C69.8282 92.0252 69.3693 96.0233 68.6046 99.9238L93.6602 87.9156L68.6046 75.9075Z" fill="#FF9C54"/>
                        <path d="M36.0831 34.921L28.2411 37.3031C39.1977 42.6943 48.1798 51.1223 53.9223 61.417L56.4668 54.0616C51.2249 46.3719 44.2588 39.8385 36.0831 34.921Z" fill="#004F6C"/>
                        <path d="M57.562 50.874L66.2383 25.7432L39.4725 33.8787C46.5498 38.4618 52.6816 44.2291 57.562 50.874Z" fill="#FF9C54"/>
                        <path d="M28.2411 138.525L36.0831 140.907C44.2727 135.99 51.2249 129.456 56.4668 121.767L53.9223 114.411C48.1659 124.706 39.1977 133.134 28.2411 138.525Z" fill="#004F6C"/>
                        <path d="M39.4647 141.948L66.2305 150.084L57.5542 124.953C52.6877 131.598 46.5559 137.365 39.4647 141.948Z" fill="#FF9C54"/>
                        <path d="M4.09607e-05 153.47L4.29153e-05 175.829L12.792 152.3C8.64851 153.039 4.3799 153.47 4.09607e-05 153.47Z" fill="#FF9C54"/>
                        <path d="M18.1504 142.494C12.4218 144.166 6.33173 145.071 0.019176 145.071L0.0191765 150.88C4.9552 150.88 9.7661 150.337 14.4101 149.39L18.1504 142.494Z" fill="#004F6C"/>
                    </svg>
                </span>
                <span className='tours-tree'>
                    <svg width="69" height="243" viewBox="0 0 79 303" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.1988 82.2977L64.7772 36.7705H13.6328L39.1988 82.2977Z" fill="#FFC800"/>
                        <path d="M39.1988 65.181L64.7772 19.6538H13.6328L39.1988 65.181Z" fill="#007699"/>
                        <path d="M39.1988 45.5148L64.7772 0H13.6328L39.1988 45.5148Z" fill="#004F6C"/>
                        <path d="M44.1549 102.699H34.6494V302.173H44.1549V102.699Z" fill="#0087A7"/>
                        <path d="M39.4045 152.271L0 94.8833L3.70343 92.521L39.4045 144.517L75.0933 92.521L78.8091 94.8833L39.4045 152.271Z" fill="#0087A7"/>
                        <path d="M39.4045 110.342L0 52.9412L3.70343 50.5913L39.4045 102.587L75.0933 50.5913L78.8091 52.9412L39.4045 110.342Z" fill="#0087A7"/>
                    </svg>
                </span>
            </div>
        </div>
    )
}

export default Tours