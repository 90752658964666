import React, { useEffect } from 'react'
import Header from '../Header/Header'
import PassSection from '../Pass-section/PassSection'
import OurPartners from '../Our-Partners/OurPartners'
import News from '../News/News'
import AllCategories from '../All-Categories/AllCategories'
import BuyNow from '../Buy-now/BuyNow'
import Services from '../Services/Services'
import Search from '../Search/Search'
import Gallery from '../Gallary/Gallery'
import DownApp from '../Download App/DownApp'
import Comments from '../Comments/Comments'

const Content = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <AllCategories />
            <Gallery />
            <PassSection />
            <OurPartners />
            {/* <News /> */}
            <BuyNow />
            <Comments />
            <DownApp />
        </>
    )
}

export default Content