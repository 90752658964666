import './App.css';
import Content from './Components/Content/Content';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Layout from './Layout';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from 'react-i18next';
import './i18n';
import { useEffect } from 'react';

axios.defaults.baseURL = 'https://yasadventure.com/dashboard/api';

function App() {
  const {i18n} = useTranslation();
  useEffect(() => {
      let lang = localStorage.getItem("lang")
      let dir = localStorage.getItem("dir")
      if(lang == null || lang.length<=0){
          localStorage.setItem("lang","en")
          localStorage.setItem("dir","ltr")
          lang ="en"
          dir ="ltr"
      }
      document.documentElement.lang=lang
      document.documentElement.dir=dir        
      i18n.changeLanguage(lang);
  },[i18n])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Layout />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
