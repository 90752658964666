import axios from 'axios';

const updateProfileImgApi = async (token, values, lang) => {
  const formData = new FormData()
  formData.append('image' , values)
  try {
    const Res = await axios.post('/updateImage', formData, {
      headers: {
        lang: lang,
        'enctype': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return Res;
  } catch (error) {
    console.log(error);
  }
};

export default updateProfileImgApi;
