import React, { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import './UploadProfileImage.css'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import updateProfileImgApi from '../../apis/auth/updateProfileImgApi';
import Cookies from 'js-cookie';

const UploadProfileImage = (props) => {
    const {t} = useTranslation();
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const [selectedFile , setSelectedFile] = useState('')


    const handleEditImg = () => {
        customApiRequest(
            updateProfileImgApi(user?.token , selectedFile),
            (res) => {
                if (res?.data?.status) {
                    props.setModelEditImg(false);
                    props.setPrevCount(props.prevCount + 1);
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.error(res?.data?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {
                toast.error('حدث خطأ', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        )
    }

    return (
        <div className='upload-files'>
            <div className='upload-files-content'>
                <div className='upload-files-info'>
                    <h2>{t('Upload-Files')}</h2>
                    <p>{t('upload-desc')}</p>
                </div>
                <div className="upload-form-container">
                    <div className='dropzone'>
                        <label htmlFor='select-files-to-upload' className='select-files-to-upload'>{t('Or')}</label>
                        <input
                            type="file"
                            id='select-files-to-upload'
                            style={{display: 'none'}}
                            value={""}
                            onChange={(e) => {
                                setSelectedFile(e.target.files[0])
                            }}
                        />
                    </div>

                    <div className="upload-manual"> 
                            {selectedFile && 
                            <div>
                                <span> {t('name-img')} : </span> <span> {selectedFile.name} </span>
                            </div>
                            }
                    </div>
                </div>
                {
                    selectedFile &&
                    <div className="submit-upload">
                        <button onClick={() => handleEditImg()}>{t('edit-img')}</button>
                    </div>
                }

                <span className="close-upload-model" onClick={() => props.setModelEditImg(false)}>
                    <Close />
                </span>
            </div>
        </div>
    )
}

export default UploadProfileImage