import React from 'react'
import notFoundImg from '../../Images/Error.png';
import './notFound.css'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const NotFound = () => {
    const {t} = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className="custom-not-found-page">
            <div className="mfa-container">
                <div className="error-wrapper">
                    <div className="err-img not-found">
                        <img src={notFoundImg} alt="Not found page"/>
                    </div>

                    <div className="err-text">
                        <h1>{t('error')}</h1>
                        <p>{t('error text')}</p>
                    </div>

                    <Link to='/' className='error-btn'>{t('Go To Home')}</Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound