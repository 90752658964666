import axios from 'axios';

const OtpVerificationApi = async (token, otp, email , type , lang) => {
  const formData = new FormData();
  formData.append('otp', otp);
  formData.append('email', email);
  type && formData.append('type', type || null);

  try {
    const response = await axios.post('/verifyOtp', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: token,
        lang: lang
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export default OtpVerificationApi;
