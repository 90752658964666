import { useTranslation } from "react-i18next";


const PageRoutes = [
    {
        id: 1,
        route: '/',
        name: 'Home',
    },
    {
        id: 2,
        route: '/tours',
        name: 'Tours',
    },
    {
        id: 3,
        route: '/aboutQatar',
        name: 'About Qatar',
    },
    {
        id: 4,
        route: '/blog',
        name: 'Blog',
    },
    {
        id: 5,
        route: '/mice',
        name: 'MICE',
    },
    {
        id: 6,
        route: '/about',
        name: 'About Us',
    },
    {
        id: 7,
        route: '/services',
        name: 'Services',
    },
    {
        id: 8,
        route: '/contact',
        name: 'Contact Us',
    },
]

function  CustomPageRoutes() {
    return PageRoutes;
}

export default PageRoutes;