import React, { useEffect } from 'react'
import './Terms.css'
import { Breadcrumbs, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest'
import termApi from '../../apis/data/termApi'
import privacyApi from '../../apis/data/privacyApi'
const Privacy = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem("lang");
    const [termsData , setTermsData] = useState();
    const customApiRequest = useCustomApiRequest();
    useEffect(() => {
        customApiRequest(
            privacyApi(lang),
            (res) => {
                if (res?.status && res?.data) {
                    const regex = /(<([^>]+)>)/gi;
                    const newString = res?.data?.data?.privacies?.replace(regex, "");
                    setTermsData(newString.split('.&nbsp;'));
                }
            }
        )
    } , [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    return (
        <div className='about'>
            <div className="about-container">
                <div className="about-container-who-we">
                    <div className="about-container-header">
                        <h2>{t('Privacy & Policy')}</h2>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="blue" href="/">
                                {t('Home')}
                            </Link>
                            <Typography color="text.primary">{t('Privacy & Policy')}</Typography>
                        </Breadcrumbs>
                    </div>
                    <div className="about-who-we-content">
                        {termsData?.map((term , index) => (
                            <p>{index + 1} . {term.slice(0, -1)}</p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy