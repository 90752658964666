import axios from "axios";

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers : {
        "access-control-allow-origin" : "https://zafaf-kw.com/dashboard/api/v1",
        "content-type" : "application/json"
    }
});

export default server;