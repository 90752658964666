import React, { useState } from 'react'
import './register.css'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../Common/AntdTextField';
import { Button, Form } from 'antd';
import eyeOffImg from '../../Images/eye-off.png';
import eyeOnImg from '../../Images/eye-on.png';
import { Link, useNavigate } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { registerApi } from '../../apis/auth/registerApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useContext } from 'react';
import UserContext from '../../contexts/UserProvider';

// let Regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
let Regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const Register = () => {
    const {setEmail} = useContext(UserContext)
    const {t} = useTranslation();
    const lang = localStorage.getItem("lang");
    const schema = Yup.object().shape({
        email: Yup.string()
            .matches(Regex , t('emailValidateCorrect'))
            .optional(t('emailValidate'))
            .email(t('emailValidateCorrect')),
        password: Yup.string()
            .required(t('passwordValidate'))
            .min(8, t('passwordValidateMin')),
        confirm_password: Yup.string()
            .required(t('passwordConfirmValidate'))
            .oneOf([Yup.ref('password')], t('passwordValidateNonTypical')),
        name: Yup.string().required(t('nameValidate')).max(30),
        // phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').typeError(t('phoneValidate')).min(8, t('phoneValidateMin')).max(15, t('phoneValidateMax')),
    });
    const [phone , setPhone] = useState()
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [passwordVisible2, setPasswordVisible2] = React.useState(false);
    const navigate = useNavigate();
    const customApiRequest = useCustomApiRequest()
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            name: '',
            phone: phone || '',
            email: '',
            password: '',
            confirm_password: '',
        }
    });
    console.log(errors);
    const onSubmit = (data) => {
        customApiRequest(
            registerApi({...data , phone} , lang),
            (res) => {
                if (res?.status && res?.data?.data?.isActive === 0) {
                    setEmail(res?.data?.data?.user?.email)
                    Cookies.set('user-token' , JSON.stringify(res?.data?.data?.user?.token))
                    Cookies.set('user-otp' , JSON.stringify(res?.data?.data?.code))
                    navigate('/OtpVerification');
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (!res?.data.status) {
                    toast.error(res?.data?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {

            }
        )
    }
    const [form] = Form.useForm();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='register-page'>
            <div className="register-page-content">
                <div className="register-page-content-form">
                    <h2>{t('register')}</h2>
                    {/* <div className='register-page-title'>
                        <span className='register-page-title-dash'></span>
                        <span> sign Up with your email</span>
                        <span className='register-page-title-dash'></span>
                    </div> */}
                    <Form
                        className="register-form"
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit(onSubmit)}
                    >
                        <div className="form-body">
                            <AntdTextField
                                className="form-text-field"
                                name="name"
                                type="text"
                                placeholder={t('name')}
                                label={t("name")}
                                errorMsg={errors?.name?.message}
                                validateStatus={errors?.name ? 'error' : ''}
                                // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                control={control}
                            />
                            <div style={{marginBottom: '15px'}}>
                                <h6 style={{marginBottom: '6px', display:'block', fontSize: '14px', fontWeight:'500'}}>{t("phone")}</h6>
                                <PhoneInput
                                    className={document.documentElement.dir == 'rtl' ? "flag-rtl" : ''}
                                    isValid={(value, country) => {
                                        if (value.match(/12345/)) {
                                        return 'Invalid value: '+value+', '+country.name;
                                        } else if (value.match(/1234/)) {
                                        return false;
                                        } else {
                                        return true;
                                        }
                                    }}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        // autoFocus: true
                                    }}
                                    label={t("phone")}
                                    placeholder={t("phone")}
                                    country={'eg'}
                                    value={phone}
                                    control={control}
                                    onChange={phone => setPhone(phone)}
                                />
                            </div>
                            {/* <AntdTextField
                                className="form-text-field"
                                name="phone"
                                type="tel"
                                placeholder={t("Phone Number")}
                                label={t("Phone Number")}
                                errorMsg={errors?.phone?.message}
                                validateStatus={errors?.phone ? 'error' : ''}
                                // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                control={control}
                            /> */}
                            <AntdTextField
                                className="form-text-field"
                                name="email"
                                type="email"
                                placeholder={t("Email us")}
                                label={t("Email us")}
                                errorMsg={errors?.email?.message}
                                validateStatus={errors?.email ? 'error' : ''}
                                // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                control={control}
                            />
                            <div className="form-text-field-password">
                                <AntdTextField
                                    className="form-text-field"
                                    name="password"
                                    label={t("password")}
                                    type={passwordVisible ? 'text' : 'password'}
                                    placeholder={t("password")}
                                    errorMsg={errors?.password?.message}
                                    // prefix={<img style={{ height: 22 }} src={lockImg} alt="lock" />}
                                    validateStatus={errors?.password ? 'error' : ''}
                                    control={control}
                                />
                                {watch('password') && (
                                    <div
                                        style={document.documentElement.dir == 'ltr' ? {right:'12px'} : {left:'12px'}}
                                        className={"eye-icon-btn"}
                                        onClick={() => {
                                            setPasswordVisible((prevState) => !prevState);
                                        }}
                                    >
                                        {passwordVisible ? (
                                            <img src={eyeOnImg} alt="eye on" />
                                        ) : (
                                            <img src={eyeOffImg} alt="eye off" />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="form-text-field-password">
                                <AntdTextField
                                    className="form-text-field"
                                    name="confirm_password"
                                    label={t('confirm password')}
                                    type={passwordVisible2 ? 'text' : 'password'}
                                    placeholder={t('confirm password')}
                                    errorMsg={errors?.confirm_password?.message}
                                    // prefix={<img style={{ height: 22 }} src={lockImg} alt="lock" />}
                                    validateStatus={errors?.confirm_password ? 'error' : ''}
                                    control={control}
                                />
                                {watch('password') && (
                                    <div
                                    style={document.documentElement.dir == 'ltr' ? {right:'12px'} : {left:'12px'}}
                                        className="eye-icon-btn"
                                        onClick={() => {
                                            setPasswordVisible2((prevState) => !prevState);
                                        }}
                                    >
                                        {passwordVisible2 ? (
                                            <img src={eyeOnImg} alt="eye on" />
                                        ) : (
                                            <img src={eyeOffImg} alt="eye off" />
                                        )}
                                    </div>
                                )}
                            </div>

                            <Button
                                className="register-btn"
                                htmlType="submit"
                                type="primary"
                                // icon={<LoginOutlined />}
                                // loading={isSubmitting}
                                // loading={isLoadingSignin}
                            >
                                {t('register')}
                            </Button>
                        </div>
                    </Form>
                    <div className='reg-new-user'>
                        <span>{t('do-have-acc')}</span>
                        <Link to='/login'>{t('login')}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register