import React, { useState } from 'react'
import './faqs.css';
import FAQImg from '../../Images/Frame.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import faqsApi from '../../apis/data/faqsApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import infoApi from '../../apis/data/infoApi';
const FAQ = () => {
    const { t } = useTranslation();
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    const [FAQs , setFAQs] = useState()
    useEffect(() => {
        customApiRequest(
            faqsApi(lang),
            (res) => {
                if (res?.data?.status) {
                    setFAQs(res?.data?.data);
                } 
            },  
        )
    } , [])

    const [getAllInfo , setGetAllInfo] = useState();
    useEffect(() => {
        customApiRequest(
            infoApi(lang),
            (res) => {
                if (res?.status && res?.data?.data) {
                    setGetAllInfo(res?.data?.data)
                }
            }
        )
    } , [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='FAQ'>
            <div className="FAQ-header">
                <img src={FAQImg} alt="FAQImg" />
                <h2>{t('Do you have some Questions?')}</h2>
                {/* <p>{t('text-Questions')}</p> */}
            </div>
            <div className="FAQ-content">
                {/* <div className="Shipping-Information">
                    <h2>{t('Shipping Information')}</h2>
                    <div className="Shipping-Information-content">
                        {
                            FAQs?.Shipping && FAQs?.Shipping?.map((ship , index) => (
                                <div>
                                    <Accordion className='pane-header' key={index}>
                                        <AccordionSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{ship?.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>{ship?.answer}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))
                        }
                        {/* <div>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div> 
                    </div>
                </div> */}
                <div className="payments">
                    <h2>{t('payments')}</h2>
                    <div className="payments-content">
                        {
                            FAQs?.payments && FAQs?.payments?.map((pay , index) => (
                                <div>
                                    <Accordion className='pane-header' key={index}>
                                        <AccordionSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{pay?.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>{pay?.answer}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))
                        }
                        {/* <div>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div> */}
                    </div>
                </div>
                <div className="warranty">
                    <h2>{t('warranty')}</h2>
                    <div className="warranty-content">
                        {
                            FAQs?.warranty && FAQs?.warranty?.map((war , index) => (
                                <div>
                                    <Accordion className='pane-header' key={index}>
                                        <AccordionSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{war?.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>{war?.answer}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))
                        }
                        {/* <div>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='pane-header'>
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>When will my order ship?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    We're always working to get your orders on their way to you as quickly as we can. Most orders will be dispatched within 2-4 business days, though there may be some delays around sales and peak periods. You'll get a notification when your order has started its journey.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="FAQ-footer">
                <h2>Can’t find what you are looking for?</h2>
                <a href={`mailto:${getAllInfo?.email}`}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1665_3648)">
                        <path d="M21.7491 3H2.24915C1.00696 3 -0.000854492 4.00781 -0.000854492 5.25V18.75C-0.000854492 19.9922 1.00696 21 2.24915 21H21.7491C22.9913 21 23.9991 19.9922 23.9991 18.75V5.25C23.9991 4.00781 22.9913 3 21.7491 3ZM2.24915 4.5H21.7491C22.1616 4.5 22.4991 4.8375 22.4991 5.25V7.19062C21.4726 8.05781 20.0054 9.25313 15.4398 12.8766C14.6476 13.5047 13.0866 15.0188 11.9991 15C10.9116 15.0188 9.34602 13.5047 8.55852 12.8766C3.9929 9.25313 2.52571 8.05781 1.49915 7.19062V5.25C1.49915 4.8375 1.83665 4.5 2.24915 4.5ZM21.7491 19.5H2.24915C1.83665 19.5 1.49915 19.1625 1.49915 18.75V9.14062C2.5679 10.0172 4.2554 11.3719 7.62571 14.0484C8.58665 14.8172 10.2835 16.5094 11.9991 16.5C13.7054 16.5141 15.3882 14.8359 16.3726 14.0484C19.7429 11.3719 21.4304 10.0172 22.4991 9.14062V18.75C22.4991 19.1625 22.1616 19.5 21.7491 19.5Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1665_3648">
                        <rect width="24" height="24" fill="white" transform="translate(-0.000854492)"/>
                        </clipPath>
                        </defs>
                    </svg>

                    Email Us
                </a>
            </div>
        </div>
    )
}

export default FAQ