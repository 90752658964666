import React from 'react';
import './successful.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SuccessfulAccount = () => {
    const {t} = useTranslation()
    return (
        <div className='Successful-account'>
            <div className='Successful-account-content'>
                <svg width="330" height="350" viewBox="0 0 434 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M234.466 481.001C335.978 481.001 418.271 451.414 418.271 414.918C418.271 378.421 335.978 348.834 234.466 348.834C132.954 348.834 50.6621 378.421 50.6621 414.918C50.6621 451.414 132.954 481.001 234.466 481.001Z" fill="#3ABBC9" fill-opacity="0.22"/>
                    <path d="M234.467 292.53C220.312 292.53 206.001 293.013 191.937 293.977C196.67 298.878 201.358 303.455 205.81 307.582C215.209 307.178 224.776 306.954 234.467 306.954C244.28 306.954 253.982 307.167 263.504 307.604C267.879 303.488 272.499 298.923 277.188 294C263.045 293.013 248.7 292.53 234.467 292.53Z" fill="#004F6C"/>
                    <path d="M295.547 295.512C295.76 295.277 295.985 295.007 296.22 294.738C334.152 251.703 354.206 205.741 354.206 161.842C354.206 95.8935 300.549 42.2598 234.601 42.2598C203.017 42.2598 174.383 55.0456 152.983 76.322C131.64 97.6207 117.519 127.387 115.018 161.326C112.012 202.488 131.146 247.677 170.379 292.058C171.411 293.213 172.42 294.368 173.452 295.467C99.0468 302.802 35.1729 323.619 35.1729 356.29C35.1729 397.71 137.853 420.074 234.444 420.074C331.068 420.074 433.737 397.722 433.737 356.29C433.759 323.641 369.93 302.836 295.547 295.512ZM129.43 162.369C133.849 102.118 179.071 56.6831 234.612 56.6831C292.62 56.6831 339.794 103.845 339.794 161.831C339.794 215.846 306.886 262.706 277.187 293.987C272.499 298.91 267.889 303.487 263.504 307.592C250.953 319.424 240.231 327.757 234.657 331.896C229.083 327.69 218.36 319.256 205.81 307.569C201.357 303.431 196.669 298.866 191.936 293.964C160.588 261.64 125.673 213.536 129.43 162.369ZM368.248 389.388C332.593 399.897 285.094 405.662 234.466 405.662C183.838 405.662 136.328 399.897 100.707 389.388C63.0328 378.307 49.6189 365.039 49.6189 356.313C49.6189 347.587 63.0328 334.319 100.707 323.238C124.697 316.172 154.071 311.237 186.171 308.792C209.421 331.986 229.464 346.05 230.507 346.757C231.729 347.621 233.176 348.058 234.623 348.058C236.036 348.058 237.461 347.654 238.661 346.836C239.648 346.163 259.769 332.457 283.109 308.825C315.085 311.281 344.336 316.205 368.259 323.249C405.91 334.33 419.347 347.598 419.347 356.324C419.347 365.05 405.899 378.307 368.248 389.388Z" fill="#004F6C"/>
                    <path d="M339.782 161.843C339.782 215.858 306.875 262.718 277.176 293.999C272.487 298.923 267.878 303.499 263.492 307.604C250.942 319.436 240.22 327.77 234.645 331.908C229.071 327.702 218.349 319.268 205.799 307.581C201.346 303.443 196.658 298.878 191.924 293.976C160.576 261.641 125.673 213.537 129.419 162.382C133.838 102.13 179.06 56.6953 234.601 56.6953C292.62 56.6953 339.782 103.858 339.782 161.843Z" fill="#FEBD44"/>
                    <path d="M234.466 208.481C258.717 208.481 278.376 188.822 278.376 164.571C278.376 140.32 258.717 120.661 234.466 120.661C210.216 120.661 190.557 140.32 190.557 164.571C190.557 188.822 210.216 208.481 234.466 208.481Z" fill="#004F6C"/>
                    <path d="M50.3815 175.069C50.3815 179.061 47.1402 182.28 43.1698 182.28H32.4025V193.025C32.4025 197.018 29.1837 200.237 25.1909 200.237C21.1981 200.237 17.9789 197.018 17.9789 193.025V182.28H7.21195C3.24156 182.28 0 179.061 0 175.069C0 171.076 3.24156 167.857 7.21195 167.857H17.9789V157.09C17.9789 153.119 21.1981 149.878 25.1909 149.878C29.1837 149.878 32.4025 153.119 32.4025 157.09V167.857H43.1698C47.1402 167.857 50.3815 171.076 50.3815 175.069Z" fill="#FEF3B2"/>
                    <path d="M372.914 42.104C361.306 42.104 351.862 32.6602 351.862 21.0519C351.862 9.44357 361.306 0 372.914 0C384.523 0 393.966 9.44357 393.966 21.0519C393.966 32.6602 384.523 42.104 372.914 42.104ZM372.914 14.4235C369.258 14.4235 366.286 17.3956 366.286 21.0519C366.286 24.7083 369.258 27.6806 372.914 27.6806C376.571 27.6806 379.543 24.7083 379.543 21.0519C379.543 17.3956 376.571 14.4235 372.914 14.4235Z" fill="#FEBD44"/>
                </svg>
                <h2>Successfully created an account</h2>
                <p>After this you can explore any place you want. enjoy it!</p>
                <Link to='/' className='error-btn'>{t('Go to Home')}</Link>
            </div>
        </div>
    )
}

export default SuccessfulAccount