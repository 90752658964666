import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Form, Rate } from 'antd';
import AntdTextAreaField from '../../Common/AntdTextAreaField';
import AntdTextField from '../../Common/AntdTextField';
import { toast } from 'react-toastify';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { addCommentApi } from '../../apis/comments/addCommentApi';
import { useState } from 'react';
import { useContext } from 'react';
import CommentsContext from '../../contexts/CommentsContext';
import { Input } from 'antd';
const { TextArea } = Input;

const AddCommentForm = () => {
    const { t } = useTranslation();
    const {setFetchCount , setOpen} = useContext(CommentsContext);
    const schema = Yup.object().shape({
        name: Yup.string().required(t('nameValidate')).max(30),
        // rate: Yup.string().required(t('')),
        commenet: Yup.string().required('enter comment'),
    });
    const [rate , setRate] = useState()
    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            name: '',
            commenet: ''
        }
    });
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    const onSubmit = (data) => {
        customApiRequest(
            addCommentApi({...data , rate : rate } , lang),
            (res) => {
                if (res?.data && res?.data.status ) {
                    setFetchCount((prev) => prev + 1)
                    setOpen(false)
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (!res?.data.status) {
                    toast.error(res?.data?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.error(res?.data?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {

            }
        )
    }
    const [form] = Form.useForm();
    return (
        <div>
            <Form
                className="comment-form"
                form={form}
                layout="vertical"
                onFinish={handleSubmit(onSubmit)}
            >
                <div className="form-body">
                    <AntdTextField
                        className="form-text-field"
                        name="name"
                        type="text"
                        placeholder={t("name")}
                        label={t("name")}
                        errorMsg={errors?.name?.message}
                        validateStatus={errors?.name ? 'error' : ''}
                        // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                        control={control}
                    />
                    <div style={{display:'flex',flexDirection:'column', marginBottom: '10px'}}>
                        <label style={{ fontSize: '16px', fontWeight: '500'}}>{t('rate')}</label>
                        <Rate name='rate' onChange={(e) => setRate(e)} allowHalf defaultValue={1} />
                    </div>
                    <AntdTextAreaField
                        className="form-text-field form-textarea-field"
                        name="commenet"
                        type="textarea"
                        placeholder={t("comment")}
                        label={t("comment")}
                        errorMsg={errors?.commenet?.commenet}
                        validateStatus={errors?.commenet ? 'error' : ''}
                        // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                        control={control}
                    />
                    {/* <TextArea className="form-text-field form-textarea-field" onChange={(e) => setComment(e.target.value)} name="commenet" control={control} rows={4} placeholder="maxLength is 6"  /> */}
                </div>
                <Button
                    className="contact-btn"
                    htmlType="submit"
                    type="primary"
                >
                    {t('send')}
                </Button>
            </Form>
        </div>
    )
}

export default AddCommentForm