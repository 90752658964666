import { createContext, useState } from "react";

const INITIAL_VALUES = {
    comments : [],
    setComments : (v) => {},
    fetchCount: 0,
    setFetchCount: (v) => {},
    open: false,
    setOpen: (v) => {},
    IsLoading: false,
    setIsLoading: (v) => {},
};

const CommentsContext = createContext(INITIAL_VALUES);

export const CommentsProvider = ({ children }) => {
    const [comments, setComments] = useState(
        INITIAL_VALUES.comments
    );
    const [open , setOpen] = useState(
        INITIAL_VALUES.open
    );
    const [IsLoading, setIsLoading] = useState(
        INITIAL_VALUES.IsLoading
    );
    const [fetchCount, setFetchCount] = useState(
        INITIAL_VALUES.fetchCount
    );
    return (
        <CommentsContext.Provider
            value={{
                comments, 
                setComments,
                fetchCount,
                setFetchCount,
                open ,
                setOpen,
                IsLoading,
                setIsLoading,
            }}
        >
        {children}
        </CommentsContext.Provider>
    );
};

export default CommentsContext;
