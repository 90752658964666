import axios from 'axios';

const OtpResendCode = async (token , lang) => {
  try {
    const res = await axios.post(`resendOtp`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
        lang: lang
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default OtpResendCode;
