import React from 'react'
import './Header.css'
import HeaderImg from '../../Images/HeaderImg.png'
// import HeaderImg from '../../Images/aboutimg.png'
import Button from '@mui/material/Button'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Player , ControlBar, ClosedCaptionButton, ReplayControl, VolumeMenuButton} from 'video-react';
// import '~video-react/dist/video-react.css';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import { useEffect } from 'react';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useState } from 'react';
import getCategoriesApi from '../../apis/categories/getCategoriesApi';
import { Link } from 'react-router-dom';
import HeaderAnimation from '../HeaderAnimation/HeaderAnimation';

const Header = () => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const customApiRequest = useCustomApiRequest();
    const [categories , setCategories] = useState();
    const lang = localStorage.getItem("lang");
    useEffect(() => {
        customApiRequest(
            getCategoriesApi(lang),
            (res) => {
                if (res?.status && res?.data?.data?.categories) {
                    setCategories(res?.data?.data?.categories)
                }
            }
        )
    } , [lang])
    
    return (
        <div className='header'>
            <div className="header-content">
                <div className="header-content-details">
                    <div className="header-text">
                        <h4 className="header-text-title">
                            {t("Rahal")}
                        </h4>
                        <p className="header-text-desc">
                            {t('is virtual assistant')}
                        </p>
                    </div>
                    <div className="header-btn">
                        <Button className='header-view-btn' variant="outlined">{t('View tours')}</Button>
                        <div style={document.documentElement.dir == 'rtl' ? {transform: 'rotate(180deg)'} : {}} className="header-play-video-icon" onClick={() => handleOpen()}>
                            <svg width="22" height="26" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.54027 1.73915C4.31567 -0.776644 0.890625 1.28432 0.890625 6.33876V43.6383C0.890625 48.6978 4.31567 50.7561 8.54027 48.2427L39.9433 29.5459C44.1693 27.0292 44.1693 22.9518 39.9433 20.4357L8.54027 1.73915Z" fill="#3ABBC9"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="header-content-img">
                    <img src={HeaderImg} alt="" />
                    {/* {categories?.length && categories?.map((categ , index) => (
                        <Link to={`tours?category_id=${categ?.id}`} className={`categ categ${index+1}`} key={index}><h4>{categ?.title}</h4></Link>
                    ))} */}
                    {/* <HeaderAnimation /> */}
                </div>
            </div>
            {/* {
            open &&    
            <div className='video-box-content'>
                <span className='close-btn' onClick={() => setOpen(false)}>
                    <CloseOutlined />
                </span>
                <Box className='video-box'>
                    <Player 
                        ref={player => {
                            console.log(player);
                    }} className='video-player' autoPlay>
                        <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                        <ControlBar onChange={(e) => console.log(e)} autoHideTime={true} autoHide={false}>
                            <ClosedCaptionButton/>
                            <VolumeMenuButton vertical />
                            <ReplayControl seconds={5} order={2.1} />
                            <ReplayControl seconds={10} order={2.2} />
                            <ReplayControl seconds={30} order={2.3} />
                        </ControlBar>
                    </Player>
                </Box>
                <div className="video-box">
                    <ReactPlayer
                        
                        url="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                        className="react-player"
                        playing
                        controls
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
            } */}
            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}
            >
                <Box className='video-box'>
                    <Player className='video-player'>
                        <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                    </Player>
                </Box>
            </Modal> */}
        </div>
    )
}

export default Header