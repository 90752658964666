import { Breadcrumbs, Button, Grid, IconButton, InputBase, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Blog.css'
import BlogImg from '../../Images/blogImg.png'
import CamelSm from '../../Images/camelSm.png'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import blogCategoriesApi from '../../apis/blog/getBlogCategories';
import getPopularPost from '../../apis/blog/getPopularPost';
import getPostsApi from '../../apis/blog/getPostsApi';
import { Spin } from 'antd';
import getSearchPostsApi from '../../apis/blog/getSearchPostsApi';

const Blog = () => {
    const {t} = useTranslation();
    let user = (Cookies.get('user') && JSON.parse(Cookies.get('user')))
    const lang = localStorage.getItem("lang");
    const customApiRequest = useCustomApiRequest()
    
    const [BlogCateg , setBlogCateg] = useState()
    useEffect(() => {
        customApiRequest(
            blogCategoriesApi(lang),
            (res) => {
                if (res?.data?.status) {
                    setBlogCateg(res?.data?.data?.categories);
                } 
            },  
            (error) => {

            }
        )
    } , [])
    const [popularPost , setPopularPost] = useState()
    useEffect(() => {
        customApiRequest(
            getPopularPost(lang),
            (res) => {
                if (res?.data?.status) {
                    setPopularPost(res?.data?.data?.posts);
                } 
            },  
            (error) => {

            }
        )
    } , [])

    const [selectedCategoryId , setSelectedCategoryId] = useState(BlogCateg && BlogCateg[0]?.id)
    const [posts , setPosts] = useState()
    useEffect(() => {
        customApiRequest(
            BlogCateg && getPostsApi(lang , selectedCategoryId || (BlogCateg && BlogCateg[0]?.id)),
            (res) => {
                if (res?.data?.status) {
                    setPosts(res?.data?.data?.posts);
                } 
            },  
            (error) => {

            }
        )
    } , [selectedCategoryId , BlogCateg])
    const [searchText , setSearchText] = useState()
    useEffect(() => {
        customApiRequest(
            getSearchPostsApi(lang , searchText),
            (res) => {
                if (res?.data?.status) {
                    setPosts(res?.data?.data?.posts);
                } 
            },  
            (error) => {

            }
        )
    } , [searchText])
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='blog'>
            <div className="blog-content">
                <div className="blog-container-header">
                    <h2>{t('Blog List')}</h2>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="blue" href="/">
                            {t('Home')}
                        </Link>
                        <Typography color="text.primary">{t('Blog List')}</Typography>
                    </Breadcrumbs>
                </div>
                <div className="blog-content-data">
                    <Grid container spacing={3}>
                        <Grid item md={8} xs={12}>
                            {
                                posts?.length > 0 ? 
                                posts?.map((pop) => (
                                    <div key={pop?.id} className="blog-card">
                                        <div className="blog-card-img">
                                            <img src={pop?.image} alt="" />
                                            {/* <span className={document.documentElement.dir === 'rtl' ? 'categ-rtl' : 'categ-ltr'}>{t('Uncategorized')}</span> */}
                                        </div>
                                        <div className="blog-card-info">
                                            <h2>{pop?.name}</h2>
                                            <div className='Blog-info'>
                                                <div>
                                                    <svg style={{position:'relative', top:'4px'}} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.1205 13.5305C12.1005 13.5305 12.0705 13.5305 12.0505 13.5305C12.0205 13.5305 11.9805 13.5305 11.9505 13.5305C9.68047 13.4605 7.98047 11.6905 7.98047 9.51047C7.98047 7.29047 9.79047 5.48047 12.0105 5.48047C14.2305 5.48047 16.0405 7.29047 16.0405 9.51047C16.0305 11.7005 14.3205 13.4605 12.1505 13.5305C12.1305 13.5305 12.1305 13.5305 12.1205 13.5305ZM12.0005 6.97047C10.6005 6.97047 9.47047 8.11047 9.47047 9.50047C9.47047 10.8705 10.5405 11.9805 11.9005 12.0305C11.9305 12.0205 12.0305 12.0205 12.1305 12.0305C13.4705 11.9605 14.5205 10.8605 14.5305 9.50047C14.5305 8.11047 13.4005 6.97047 12.0005 6.97047Z" fill="#999999"/>
                                                        <path d="M12.0008 22.7503C9.31081 22.7503 6.74081 21.7503 4.75081 19.9303C4.57081 19.7703 4.49081 19.5303 4.51081 19.3003C4.64081 18.1103 5.38081 17.0003 6.61081 16.1803C9.59081 14.2003 14.4208 14.2003 17.3908 16.1803C18.6208 17.0103 19.3608 18.1103 19.4908 19.3003C19.5208 19.5403 19.4308 19.7703 19.2508 19.9303C17.2608 21.7503 14.6908 22.7503 12.0008 22.7503ZM6.08081 19.1003C7.74081 20.4903 9.83081 21.2503 12.0008 21.2503C14.1708 21.2503 16.2608 20.4903 17.9208 19.1003C17.7408 18.4903 17.2608 17.9003 16.5508 17.4203C14.0908 15.7803 9.92081 15.7803 7.44081 17.4203C6.73081 17.9003 6.26081 18.4903 6.08081 19.1003Z" fill="#999999"/>
                                                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#999999"/>
                                                    </svg>
                                                    <span>{pop?.addedBy}</span>
                                                </div>
                                                <div>
                                                    <svg style={{position:'relative', top:'4px'}} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z" fill="#999999"/>
                                                        <path d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z" fill="#999999"/>
                                                        <path d="M8.5 14.5003C8.37 14.5003 8.24 14.4703 8.12 14.4203C7.99 14.3703 7.89 14.3003 7.79 14.2103C7.61 14.0203 7.5 13.7703 7.5 13.5003C7.5 13.3703 7.53 13.2403 7.58 13.1203C7.63 13.0003 7.7 12.8903 7.79 12.7903C7.89 12.7003 7.99 12.6303 8.12 12.5803C8.48 12.4303 8.93 12.5103 9.21 12.7903C9.39 12.9803 9.5 13.2403 9.5 13.5003C9.5 13.5603 9.49 13.6303 9.48 13.7003C9.47 13.7603 9.45 13.8203 9.42 13.8803C9.4 13.9403 9.37 14.0003 9.33 14.0603C9.3 14.1103 9.25 14.1603 9.21 14.2103C9.02 14.3903 8.76 14.5003 8.5 14.5003Z" fill="#999999"/>
                                                        <path d="M12 14.4999C11.87 14.4999 11.74 14.4699 11.62 14.4199C11.49 14.3699 11.39 14.2999 11.29 14.2099C11.11 14.0199 11 13.7699 11 13.4999C11 13.3699 11.03 13.2399 11.08 13.1199C11.13 12.9999 11.2 12.8899 11.29 12.7899C11.39 12.6999 11.49 12.6299 11.62 12.5799C11.98 12.4199 12.43 12.5099 12.71 12.7899C12.89 12.9799 13 13.2399 13 13.4999C13 13.5599 12.99 13.6299 12.98 13.6999C12.97 13.7599 12.95 13.8199 12.92 13.8799C12.9 13.9399 12.87 13.9999 12.83 14.0599C12.8 14.1099 12.75 14.1599 12.71 14.2099C12.52 14.3899 12.26 14.4999 12 14.4999Z" fill="#999999"/>
                                                        <path d="M15.5 14.4999C15.37 14.4999 15.24 14.4699 15.12 14.4199C14.99 14.3699 14.89 14.2999 14.79 14.2099C14.75 14.1599 14.71 14.1099 14.67 14.0599C14.63 13.9999 14.6 13.9399 14.58 13.8799C14.55 13.8199 14.53 13.7599 14.52 13.6999C14.51 13.6299 14.5 13.5599 14.5 13.4999C14.5 13.2399 14.61 12.9799 14.79 12.7899C14.89 12.6999 14.99 12.6299 15.12 12.5799C15.49 12.4199 15.93 12.5099 16.21 12.7899C16.39 12.9799 16.5 13.2399 16.5 13.4999C16.5 13.5599 16.49 13.6299 16.48 13.6999C16.47 13.7599 16.45 13.8199 16.42 13.8799C16.4 13.9399 16.37 13.9999 16.33 14.0599C16.3 14.1099 16.25 14.1599 16.21 14.2099C16.02 14.3899 15.76 14.4999 15.5 14.4999Z" fill="#999999"/>
                                                        <path d="M8.5 18.0002C8.37 18.0002 8.24 17.9702 8.12 17.9202C8 17.8702 7.89 17.8002 7.79 17.7102C7.61 17.5202 7.5 17.2602 7.5 17.0002C7.5 16.8702 7.53 16.7402 7.58 16.6202C7.63 16.4902 7.7 16.3802 7.79 16.2902C8.16 15.9202 8.84 15.9202 9.21 16.2902C9.39 16.4802 9.5 16.7402 9.5 17.0002C9.5 17.2602 9.39 17.5202 9.21 17.7102C9.02 17.8902 8.76 18.0002 8.5 18.0002Z" fill="#999999"/>
                                                        <path d="M12 18.0002C11.74 18.0002 11.48 17.8902 11.29 17.7102C11.11 17.5202 11 17.2602 11 17.0002C11 16.8702 11.03 16.7402 11.08 16.6202C11.13 16.4902 11.2 16.3802 11.29 16.2902C11.66 15.9202 12.34 15.9202 12.71 16.2902C12.8 16.3802 12.87 16.4902 12.92 16.6202C12.97 16.7402 13 16.8702 13 17.0002C13 17.2602 12.89 17.5202 12.71 17.7102C12.52 17.8902 12.26 18.0002 12 18.0002Z" fill="#999999"/>
                                                        <path d="M15.5 17.9999C15.24 17.9999 14.98 17.8899 14.79 17.7099C14.7 17.6199 14.63 17.5099 14.58 17.3799C14.53 17.2599 14.5 17.1299 14.5 16.9999C14.5 16.8699 14.53 16.7399 14.58 16.6199C14.63 16.4899 14.7 16.3799 14.79 16.2899C15.02 16.0599 15.37 15.9499 15.69 16.0199C15.76 16.0299 15.82 16.0499 15.88 16.0799C15.94 16.0999 16 16.1299 16.06 16.1699C16.11 16.1999 16.16 16.2499 16.21 16.2899C16.39 16.4799 16.5 16.7399 16.5 16.9999C16.5 17.2599 16.39 17.5199 16.21 17.7099C16.02 17.8899 15.76 17.9999 15.5 17.9999Z" fill="#999999"/>
                                                        <path d="M20.5 9.83984H3.5C3.09 9.83984 2.75 9.49984 2.75 9.08984C2.75 8.67984 3.09 8.33984 3.5 8.33984H20.5C20.91 8.33984 21.25 8.67984 21.25 9.08984C21.25 9.49984 20.91 9.83984 20.5 9.83984Z" fill="#999999"/>
                                                        <path d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V17C21.75 20.65 19.65 22.75 16 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z" fill="#999999"/>
                                                    </svg>
                                                    <span>{pop?.created_at}</span>
                                                </div>
                                            </div>
                                            <p>{pop?.desc}</p>
                                            <Link className='blog-card-btn' size="small" to={`/BlogDetails?post_id=${pop?.id}`}>
                                                {t('Read More')} 
                                                <svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.40414 0.524417C5.28309 0.643733 5.28309 0.840029 5.40414 0.959346L7.86412 3.38417L0.312378 3.38417C0.14057 3.38417 -1.53984e-07 3.52273 -1.61386e-07 3.69209L-1.88305e-07 4.30791C-1.95708e-07 4.47727 0.14057 4.61583 0.312378 4.61583L7.86802 4.61583L5.40804 7.04065C5.287 7.15997 5.287 7.35627 5.40804 7.47558L5.84928 7.91051C5.97032 8.02983 6.16946 8.02983 6.29051 7.91051L9.81648 4.43493C9.93752 4.31561 10 4.15781 10 4C10 3.84219 9.93752 3.68439 9.81648 3.56507L6.28661 0.0894874C6.16556 -0.0298294 5.96642 -0.0298294 5.84537 0.0894874L5.40414 0.524417Z" fill="#F4B62B"/>
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className='blog-content-data-spin'>
                                    {
                                    posts?.length > 0 ? 
                                    <Spin />
                                    : 
                                    <div className='no-products-post'>{t('no-posts')}</div> 
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <div className="blog-filter">
                                <div className='blog-search'>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        style={{height: '50px'}}
                                        placeholder="Search..."
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    {/* <IconButton  type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton> */}
                                </div>
                                <div className="blog-categories">
                                    <h3>{t('blog categories')}</h3>
                                    <div className="blog-categories-content">
                                        {
                                            BlogCateg?.length && BlogCateg?.map((cat) => (
                                                <div key={cat?.id} className='blog-categories-content-list'>
                                                    <h3 onClick={() => setSelectedCategoryId(cat?.id)}>{cat?.name}</h3>
                                                    <span>( {cat?.postCount} )</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="blog-posts">
                                    <h3>{t('popular post')}</h3>
                                    <div className='blog-posts-content'>
                                        {
                                            popularPost?.length && popularPost?.map((pop) => (
                                                <div key={pop?.id} className="blog-posts-card">
                                                    <Link to={`/BlogDetails?post_id=${pop?.id}`}><img src={pop?.image} alt="" /></Link>
                                                    <div>
                                                        <span>{pop?.created_at}</span>
                                                        <h3>{pop?.name}</h3>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {/* <div className="blog-posts-card">
                                            <img src={CamelSm} alt="" />
                                            <div>
                                                <span>Jan 28, 2021</span>
                                                <h3>Organize your work desk in color!</h3>
                                            </div>
                                        </div>
                                        <div className="blog-posts-card">
                                            <img src={CamelSm} alt="" />
                                            <div>
                                                <span>Jan 28, 2021</span>
                                                <h3>Organize your work desk in color!</h3>
                                            </div>
                                        </div>
                                        <div className="blog-posts-card">
                                            <img src={CamelSm} alt="" />
                                            <div>
                                                <span>Jan 28, 2021</span>
                                                <h3>Organize your work desk in color!</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className="blog-tags">
                                    <h3>{t('popular tags')}</h3>
                                    <div className='blog-tags-content'>
                                        <a href='/'>envato</a>
                                        <a href='/'>electronics</a>
                                        <a href='/'>Store</a>
                                        <a href='/'>tires</a>
                                        <a href='/'>Quality</a>
                                        <a href='/'>envato</a>
                                        <a href='/'>electronics</a>
                                        <a href='/'>Store</a>
                                        <a href='/'>tires</a>
                                        <a href='/'>Quality</a>
                                    </div>
                                </div> */}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Blog