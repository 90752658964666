import axios from 'axios';

const deleteNotificationApi = async (token, values) => {
    try {
        const deleteRes = await axios.post('/deleteNotification', values, {
        headers: {
            lang: 'ar',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token
        }
        });
        return deleteRes;
    } catch (error) {
        console.log(error);
    }
};

export default deleteNotificationApi;
