import axios from 'axios';

const getPostsApi = async (lang , blog_id) => {
    try {
        const res = await axios.get(`/getPosts?blog_id=${blog_id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            // Authorization: 'Bearer ' + token,
            lang: lang
        }
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default getPostsApi;