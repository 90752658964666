import { createContext, useState } from "react";

const INITIAL_VALUES = {
    favourites : [],
    setFavourites : (v) => {},
    fetchCount: 0,
    setFetchCount: (v) => {},
    ModalOpened: false,
    setModalOpened: (v) => {},
    IsLoading: false,
    setIsLoading: (v) => {},
};

const FavouritesContext = createContext(INITIAL_VALUES);

export const FavouritesProvider = ({ children }) => {
    const [favourites, setFavourites] = useState(
        INITIAL_VALUES.favourites
    );
    const [ModalOpened, setModalOpened] = useState(
        INITIAL_VALUES.ModalOpened
    );
    const [IsLoading, setIsLoading] = useState(
        INITIAL_VALUES.IsLoading
    );
    const [fetchCount, setFetchCount] = useState(
        INITIAL_VALUES.fetchCount
    );
    return (
        <FavouritesContext.Provider
            value={{
                favourites, 
                setFavourites,
                fetchCount,
                setFetchCount,
                ModalOpened,
                setModalOpened,
                IsLoading,
                setIsLoading,
            }}
        >
        {children}
        </FavouritesContext.Provider>
    );
};

export default FavouritesContext;
