import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './Login.css'
import { Button, Form } from 'antd';
import eyeOffImg from '../../Images/eye-off.png';
import eyeOnImg from '../../Images/eye-on.png';
import lockImg from '../../Images/lock.png';
import { Link, useNavigate } from 'react-router-dom';
import AntdTextField from '../../Common/AntdTextField';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { LoginApi } from '../../apis/auth/LoginApi';
import { Store } from 'react-notifications-component';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

let Regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Login = () => {
    const {t} = useTranslation();
    const schema = Yup.object().shape({
        email: Yup.string()
            .matches(Regex , t('emailValidateCorrect'))
            .optional(t('emailValidate'))
            .email(t('emailValidateCorrect')),
        password: Yup.string()
            .required(t('passwordValidate'))
            .min(8, t('passwordValidateMin')),
    });
    const [passwrodVisible, setPasswordVisible] = React.useState(false);
    const customApiRequest = useCustomApiRequest();
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            email : '',
            password: '',
        }
    });
    const onSubmit = (data) => {
        customApiRequest(
            LoginApi(data),
            (res) => {
                if (res?.status === 200 && res?.data?.data?.isActive === 1) {
                    Cookies.set('user' , JSON.stringify(res?.data?.data?.user))
                    navigate('/');
                    toast.success(res?.data?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else if (res?.data?.data?.isActive === 0) {
                    Cookies.set('user-token' , JSON.stringify(res?.data?.data?.user?.token))
                    navigate('/OtpVerification');
                    toast.error(res?.data?.message, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.error(res?.data?.message, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            },  
            (error) => {
                toast.error(`${t('try again')}`, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        )
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [form] = Form.useForm();
    return (
        <div className='login-page'>
            <div className="login-page-content">
                <div className="login-page-content-form">
                    <h2>{t('login')}</h2>
                    {/* <div className='login-page-title'>
                        <span className='login-page-title-dash'></span>
                        <span>{t('Login with your email')}</span>
                        <span className='login-page-title-dash'></span>
                    </div> */}
                    <Form
                        className="login-form"
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit(onSubmit)}
                    >
                        <div className="form-body">
                            <AntdTextField
                                className="form-text-field"
                                name="email"
                                type="email"
                                placeholder={t("Email us")}
                                label={t("Email us")}
                                errorMsg={errors?.email?.message}
                                validateStatus={errors?.email ? 'error' : ''}
                                // prefix={<img style={{ height: 22 }} src={'userImg'} alt="user" />}
                                control={control}
                            />
                            <div className="form-text-field-password">
                                <AntdTextField
                                    className="form-text-field"
                                    name="password"
                                    label={t('password')}
                                    type={passwrodVisible ? 'text' : 'password'}
                                    placeholder={t('password')}
                                    errorMsg={errors?.password?.message}
                                    // prefix={<img style={{ height: 22 }} src={lockImg} alt="lock" />}
                                    validateStatus={errors?.password ? 'error' : ''}
                                    control={control}
                                />
                                {watch('password') && (
                                    <div
                                        style={document.documentElement.dir == 'ltr' ? {right:'12px'} : {left:'12px'}}
                                        className="eye-icon-btn"
                                        onClick={() => {
                                            setPasswordVisible((prevState) => !prevState);
                                        }}
                                    >
                                        {passwrodVisible ? (
                                            <img src={eyeOnImg} alt="eye on" />
                                        ) : (
                                            <img src={eyeOffImg} alt="eye off" />
                                        )}
                                    </div>
                                )}
                            </div>

                            <Button
                                className="login-btn"
                                htmlType="submit"
                                type="primary"
                                // icon={<LoginOutlined />}
                                // loading={isSubmitting}
                                // loading={isLoadingSignin}
                            >
                                {t('login')}
                            </Button>
                        </div>
                    </Form>
                    <div className='forgot-password'>
                        <Link to='/forgotpassword'>{t('Forgot Password')}</Link>
                    </div>
                    <div className='reg-new-user'>
                        <span>{t('do-not-have-acc')}</span>
                        <Link to='/register'>{t('register')}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login